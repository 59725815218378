import { IsString, IsUUID, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { ValidateDTO } from '..'

import {
  BASIC_PCF_CALCULATIONS_CONTROLLER,
  BasicCalculationFacilityForm,
} from '../../../lib/DTO/basic-pcf-calculations/basic-pcf-calculations-cache.common-vars'

export const SUBMIT_BASIC_PCF_CACHE_SUBPATH = 'basic-pcf/cache/:cacheId/submit'
export const SUBMIT_BASIC_PCF_CACHE_URI = `${BASIC_PCF_CALCULATIONS_CONTROLLER}/${SUBMIT_BASIC_PCF_CACHE_SUBPATH}`

export class SubmitBasicPCFCacheDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  cacheId!: string

  @IsString()
  currency!: string

  @ValidateNested()
  @Type(() => BasicCalculationFacilityForm)
  facilitiesForms!: BasicCalculationFacilityForm[]
}

export type SubmitBasicPCFCacheRes = {
  success: true
}
