// eslint-disable-next-line max-classes-per-file
import { IsString, IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODELS_SNAPSHOT_FACILITIES_SUBPATH = '/products/:productId/facilities'
export const GET_PRODUCT_MODELS_SNAPSHOT_FACILITIES_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODELS_SNAPSHOT_FACILITIES_SUBPATH}`

export interface IGetFacilitiesArgs {
  orgId: string
  productId: string
}

export class GetProductModellingSnapshotFacilitiesDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  productId!: string
}

export interface ISnapshotFacilities {
  facilityName: string
  facilityId: string
}

export type GetProductModellingSnapshotFacilitiesDTORes = ISnapshotFacilities[]
