// eslint-disable-next-line max-classes-per-file
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, Min } from 'class-validator'

import { Expose } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODELS_PRODUCTS_SUBPATH = '/products'
export const GET_PRODUCT_MODELS_PRODUCTS_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODELS_PRODUCTS_SUBPATH}`

export enum PRODUCT_MODELLING_PRODUCT_SORT_FIELDS {
  PRODUCT_NAME = 'productName',
  SUPPLIER_NAME = 'supplierName',
  CATEGORY = 'category',
}

export interface IGetProductsArgs {
  orgId: string
  category: string
  searchStr: string
  perPage: number
  pageNo: number
  sortOrder: SORT_ORDER
  sortField: PRODUCT_MODELLING_PRODUCT_SORT_FIELDS
}

export class GetProductModellingProductsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  @IsNotEmpty()
  @IsOptional()
  category?: string

  @IsString()
  @IsNotEmpty()
  @IsOptional()
  searchStr?: string

  @IsNumber()
  @Min(1)
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @Min(1)
  @IsOptional()
  perPage?: number

  @IsEnum(PRODUCT_MODELLING_PRODUCT_SORT_FIELDS)
  @IsOptional()
  sortField?: PRODUCT_MODELLING_PRODUCT_SORT_FIELDS

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: SORT_ORDER
}

export class ProductModellingProductsEntity {
  @Expose()
  id = ''

  @Expose()
  productName = ''

  @Expose()
  supplierName = ''

  @Expose()
  category = ''
}

export interface GetProductModellingProductsDTORes {
  products: ProductModellingProductsEntity[]

  meta: {
    pageNo: number
    perPage: number
    count: number
    totalCount: number
  }
}
