import { IsUUID, IsString } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const FULFILL_PACT_REQUEST_SUBPATH = 'fulfill/:id'
export const FULFILL_PACT_REQUEST_URI = `${PACT_REQUEST_CONTROLLER}/${FULFILL_PACT_REQUEST_SUBPATH}`

export class FulfillPactRequestDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  requestId!: string

  @IsString()
  productId!: string
}

export type FulfillPactRequestDTORes = object
