// eslint-disable-next-line max-classes-per-file
import { IsEnum, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator'

import { Expose, Transform } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { ENUM_EF_SOURCES } from '../../../@enums/product-modelling.enums'

import type { IProductModelInput } from '../../../@types/product-modelling/product-modelling-inputs.types'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const UPDATE_PRODUCT_MODEL_INPUT_SUBPATH = '/inputs/:inputId'
export const UPDATE_PRODUCT_MODEL_INPUT_URI = `${PRODUCT_MODELLING_CONTROLLER}${UPDATE_PRODUCT_MODEL_INPUT_SUBPATH}`

export class UpdateProductModelInputDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  inputId!: string

  @IsString()
  @IsOptional()
  activity?: string

  @IsString()
  @IsOptional()
  supplierName?: string

  @IsEnum(ENUM_EF_SOURCES)
  @IsOptional()
  source?: ENUM_EF_SOURCES

  @IsString()
  @IsOptional()
  linkToSource?: string

  @IsNumber()
  @IsOptional()
  remodelledQuantity?: number

  @IsNumber()
  @IsOptional()
  emissionFactor?: number
}

export type UpdateProductModelInputDTORes = {
  inputId: string
}

export class UpdateProductModelInputEntity implements Partial<IProductModelInput> {
  @Expose()
  activity = ''

  @Expose()
  supplierName = ''

  @Expose({ name: 'emissionFactor' })
  emissionFactor = 0

  @Expose()
  @Transform(({ value }) => value ?? ENUM_EF_SOURCES.ESTIMATE)
  source: ENUM_EF_SOURCES

  @Expose()
  linkToSource: string

  @Expose({ name: 'remodelledQuantity' })
  remodelledQuantity: number

  @Expose()
  @Transform(({ obj }) => obj.remodelledQuantity * obj.emissionFactor)
  calculatedInputEmissions: number
}
