import { IsOptional, IsString, IsUUID, Length } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const UPDATE_PRODUCT_MODELLING_SUBPATH = '/:initiativeId'
export const UPDATE_PRODUCT_MODELLING_URI = `${PRODUCT_MODELLING_CONTROLLER}${UPDATE_PRODUCT_MODELLING_SUBPATH}`

export class UpdateProductModellingDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  initiativeId!: string

  @IsString()
  @Length(3)
  @IsOptional()
  initiativeName?: string
}

export type UpdateProductModellingDTORes = {
  initiativeId: string
}

export interface IUpdateProductModelArgs extends UpdateProductModellingDTOReq {
  orgId: string
}
