import { IsNumber, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import {
  BASIC_PCF_CALCULATIONS_CONTROLLER,
  BasicCalculationFacilityForm,
} from '../../../lib/DTO/basic-pcf-calculations/basic-pcf-calculations-cache.common-vars'

export const GET_BASIC_PCF_CACHE_SUBPATH = 'cache/:year'
export const GET_BASIC_PCF_CACHE_URI = `${BASIC_PCF_CALCULATIONS_CONTROLLER}/${GET_BASIC_PCF_CACHE_SUBPATH}`

export class GetBasicPCFCacheDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsNumber()
  year!: number
}

export type GetBasicPCFCacheRes = {
  facilitiesForms: BasicCalculationFacilityForm[]
  year: number
  cacheId: string
}
