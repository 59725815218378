import { IsArray, IsOptional, IsNumber, IsString, ValidateIf, IsBoolean, ArrayMinSize } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_STRUCTURE_FACILITIES_SUBPATH = 'structures/facilities'
export const GET_PRODUCT_STRUCTURE_FACILITIES_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_STRUCTURE_FACILITIES_SUBPATH}`

export class GetProductStructureFacilitiesDTO extends ValidateDTO {
  @IsString({ each: true })
  @IsArray()
  @ValidateIf(o => o.allProductsSelected === false)
  @ArrayMinSize(1)
  productIds?: string[]

  @ValidateIf(o => o.allProductsSelected)
  @IsArray()
  productCategory?: string[]

  @ValidateIf(o => o.allProductsSelected)
  @IsString()
  productQuery?: string

  @IsBoolean()
  @IsOptional()
  allProductsSelected?: boolean

  @IsString()
  @IsOptional()
  startDate?: string

  @IsString()
  @IsOptional()
  endDate?: string

  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number
}

export type Facility = {
  id: string
  name: string
}

export type GetProductStructureFacilitiesDTORes = {
  facilities: Facility[]
  meta: {
    perPage?: number
    pageCount?: number
    totalCount: number
  }
}
