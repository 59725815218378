import { IsOptional, IsString, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const CREATE_PACT_REQUEST_CONNECTIONS_SUBPATH = 'connections'
export const CREATE_PACT_REQUEST_CONNECTIONS_URI = `${PACT_REQUEST_CONTROLLER}/${CREATE_PACT_REQUEST_CONNECTIONS_SUBPATH}`

export class CreatePactRequestConnectionsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  label!: string

  @IsOptional()
  @IsString()
  clientId?: string

  @IsOptional()
  @IsString()
  clientSecret?: string

  @IsOptional()
  @IsString()
  clientSubPath?: string
}

export type CreatePactRequestConnectionsDTORes = object
