import {
  IsUUID,
  IsString,
  IsArray,
  IsOptional,
  IsNumber,
  IsEnum,
  ValidateIf,
  IsBoolean,
  ArrayMinSize,
} from 'class-validator'

import { ValidateDTO } from '..'

import { SORT_ORDER } from '../../../../imports/@enums/common.enums'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'
import type { StructureInput } from './get-product-structure-inputs.dto'

export enum STRUCTURE_SORT_FIELD {
  PRODUCT_NAME = 'productName',
  CUSTOMER_STRUCTURE_ID = 'customerStructureId',
  FACILITY_NAME = 'facilityName',
  VALID_FROM = 'validFrom',
  FACILITY_ID = 'facilityId',
  INPUTS = 'inputs',
  INPUT_COUNT = 'inputCount',
}

export const GET_PRODUCT_STRUCTURES_SUBPATH = 'structures'
export const GET_PRODUCT_STRUCTURES_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_STRUCTURES_SUBPATH}`

export class GetProductStructuresDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  @IsOptional()
  startDate?: string

  @IsString()
  @IsOptional()
  endDate?: string

  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: string

  @IsEnum(STRUCTURE_SORT_FIELD)
  @IsOptional()
  sortField?: string

  @ValidateIf(o => o.allStructuresSelected === false)
  @IsArray()
  @IsOptional()
  structureIds?: string[]

  @IsBoolean()
  @IsOptional()
  allStructuresSelected?: boolean

  @ValidateIf(o => o.allStructuresSelected)
  @IsArray()
  @IsOptional()
  structureFacilities?: string[]

  @ValidateIf(o => o.allStructuresSelected)
  @IsString()
  @IsOptional()
  structureQuery?: string

  @IsBoolean()
  @IsOptional()
  allProductsSelected?: boolean

  @ValidateIf(o => o.allProductsSelected === false)
  @IsString({ each: true })
  @IsArray()
  @ArrayMinSize(1)
  productIds?: string[]

  @ValidateIf(o => o.allProductsSelected)
  @IsArray()
  @IsOptional()
  productCategory?: string[]

  @ValidateIf(o => o.allProductsSelected)
  @IsString()
  @IsOptional()
  productQuery?: string
}

export type ProductStructure = {
  id: string
  facilityName: string
  productName: string
  facilityId: string
  validFrom: string
  customerStructureId: string
  inputCount: number
  inputs: StructureInput[]
  totalFilteredCount: number // remove?
}

export type GetProductStructuresDTORes = {
  structures: ProductStructure[]
  meta: {
    perPage?: number
    pageNo?: number
    totalCount: number
    totalFilteredCount: number
  }
}
