import type { AxiosInstance } from 'axios'
import { useMemoize } from '@vueuse/core'
import { replaceUriParams } from '@lib/DTO'

import {
  GET_SUPPLIERS_PATH,
  type GetSuppliersDTO,
  type GetSuppliersDTORes,
} from '@lib/DTO/suppliersv2/get-suppliers.dto'

import {
  GET_SUPPLIERS_FOR_COHORTS_PATH,
  type GetSuppliersForCohortsDTO,
  type GetSuppliersForCohortsDTORes,
} from '@lib/DTO/suppliersv2/get-suppliers-for-cohorts.dto'

import {
  ACCEPT_SUPPLIER_INVITE_PATH,
  type AcceptInviteDTO,
  type AcceptSupplierInviteDTORes,
} from '@lib/DTO/suppliersv2/accept-invite.dto'
import { GET_SUPPLIER_INVITE_PATH, type GetSupplierInviteDTORes } from '@lib/DTO/suppliersv2/get-supplier-invite.dto'
import { RESEND_SUPPLIER_INVITE_PATH, type ResendInviteDTORes } from '@lib/DTO/suppliersv2/resend-invite.dto'
import {
  UPDATE_INVITE_EMAIL_PATH,
  type UpdateInviteDTORes,
  type UpdateInviteEmailDTO,
} from '@lib/DTO/suppliersv2/update-invite-email.dto'
import {
  UPDATE_SUPPLIER_EMAILS_PATH,
  type UpdateSupplierEmailsDTO,
  type UpdateSupplierEmailsDTORes,
} from '@lib/DTO/suppliersv2/update-supplier-emails.dto'
import { GET_SUPPLIER_PATH, type GetSupplierDTO, type GetSupplierDTORes } from '@lib/DTO/suppliersv2/get-supplier.dto'
import {
  EDIT_SUPPLIER_PATH,
  type EditSupplierDTO,
  type EditSupplierDTORes,
} from '@lib/DTO/suppliersv2/edit-supplier-associations.dto'
import {
  MERGE_SUPPLIERS_PATH,
  type MergeSuppliersDTO,
  type MergeSuppliersDTORes,
} from '@lib/DTO/suppliersv2/merge-suppliers.dto'
import {
  GET_SUPPLIER_ASSOCIATIONS_PATH,
  type GetSupplierAssociationsDTO,
  type GetSupplierAssociationsDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-associations.dto'
import { DETACH_SUPPLIERS_PATH, DetachSupplierDto } from '@lib/DTO/suppliersv2/detach-supplier.dto'
import {
  GET_SUPPLIER_ASSOCIATED_PURCHASES_PATH,
  type GetSupplierAssociatedPurchasesDTO,
  type GetSupplierAssociatedPurchasesDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-associated-purchases.dto'

import {
  GET_SUPPLIER_PURCHASES_PATH,
  GetSupplierPurchasesDTO,
  type GetSupplierPurchasesDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-purchases.dto'

import {
  GET_SUPPLIER_SKU_PURCHASES_PATH,
  GetSupplierSKUPurchasesDTO,
  type GetSupplierSKUPurchasesDTORes,
} from '@lib/DTO/supplier-sku-purchases/get-supplier-sku-purchases.dto'

import {
  GET_SUPPLIER_SKU_METADATA_PATH,
  GetSupplierSKUMetadataDTO,
  type GetSupplierSKUMetadataDTORes,
} from '@lib/DTO/supplier-sku-purchases/get-supplier-sku-metadata.dto'

import { GET_ORGANISATION_PURCHASES_PATH } from '@lib/DTO/purchases/get-organisation-purchases.dto'

import {
  ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_PATH,
  ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_PATH,
  ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_PATH,
  type AddSupplierKnownProductRequestDTORes,
  AddSupplierProductRequestAndAssignDTO,
  AddSupplierProductRequestAndInviteDTO,
  AddSupplierProductRequestDTO,
} from '@lib/DTO/supplier-product-requests/add-product-request.dto'

import {
  ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_V2_PATH,
  ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_V2_PATH,
  ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_V2_PATH,
  AddSupplierProductRequestAndAssignDTOV2,
  AddSupplierProductRequestAndInviteDTOV2,
  AddSupplierProductRequestDTOV2,
} from '@lib/DTO/supplier-product-requests/add-product-request-v2.dto'

import {
  ADD_SUPPLIER_CORPORATE_REQUESTS_PATH,
  type AddSupplierCorporateRequestsDto,
} from '@lib/DTO/supplier-corporate-requests/add-supplier-corporate-requests.dto'

import {
  GET_SUPPLIER_CORPORATE_REQUESTS_PATH,
  type GetSupplierCorporateRequestsDTO,
  type GetSupplierCorporateRequestsDTORes,
} from '@lib/DTO/supplier-corporate-requests/get-supplier-corporate-requests.dto'

import {
  GET_SUPPLIER_POTENTIAL_MATCHES_PATH,
  type GetSupplierPotentialMatchesDTO,
  type GetSupplierPotentialMatchesDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-potential-matches.dto'

import {
  GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_PATH,
  type GetSupplierPotentialMatchesByNameDTO,
  type GetSupplierPotentialMatchesByNameDTORes,
} from '@lib/DTO/suppliersv2/get-supplier-potential-matches-by-name.dto'

import {
  GET_UNREQUESTED_SUPPLIERS_PATH,
  type GetUnrequestedSuppliersDto,
  type GetUnrequestedSuppliersDtoRes,
} from '@lib/DTO/suppliersv2/get-unrequested-suppliers.dto'

import {
  GET_SUPPLIER_COHORTS_DETAILED_PATH,
  type GetSupplierCohortsDetailedDTO,
  type GetSupplierCohortsDetailedDTORes,
} from '@lib/DTO/supplier-cohorts/get-supplier-cohorts-detailed.dto'

import { DELETE_SUPPLIER_COHORT_PATH } from '@lib/DTO/supplier-cohorts/delete-supplier-cohort.dto'

import {
  CREATE_SUPPLIER_COHORT_PATH,
  type CreateSupplierCohortDTO,
} from '@lib/DTO/supplier-cohorts/create-supplier-cohort.dto'

import { EDIT_SUPPLIER_COHORT_PATH } from '@lib/DTO/supplier-cohorts/edit-supplier-cohort.dto'

import {
  GET_SUPPLIER_COHORTS_PATH,
  GetSupplierCohortsDTO,
  type GetSupplierCohortsDTORes,
} from '@lib/DTO/supplier-cohorts'

import { GET_SUPPLIERS_METRICS_PATH, type GetSuppliersMetricsDTO } from '@lib/DTO/suppliersv2/suppliers-stats.dto'

import type {
  GetAnnualRespondedDataRequestsPayloadV2,
  GetAnnualRespondedDataRequestsResponse,
} from '@/imports/@types/DataRequest'

import type {
  SupplierFootprint,
  SupplierFootprintsGetResponse,
  SupplierFootprintUpdateResponse,
} from '@/imports/@types/supplierFootprints'

import {
  GET_SUPPLIERS_SIMPLE_PATH,
  type GetSuppliersSimpleDTO,
  type GetSuppliersSimpleDTORes,
} from '@/imports/lib/DTO/suppliersv2/get-suppliers-simple.dto'

import {
  DETACH_SUPPLIER_COHORT_PATH,
  DetachSupplierCohortDto,
} from '@/imports/lib/DTO/supplier-cohorts/detach-supplier-cohort.dto'

import {
  GET_SUPPLIER_UNREQUESTED_SKU_PURCHASES_PATH,
  type GetSupplierUnrequestedSKUPurchasesDTO,
  type GetSupplierUnrequestedSKUPurchasesDTORes,
} from '@/imports/lib/DTO/supplier-sku-purchases/get-supplier-unrequested-sku-purchases.dto'

import {
  type GetSupplierSKUCategoryDepthDTO,
  type GetSupplierSKUCategoryDepthDTORes,
  GET_SUPPLIER_SKU_CATEGORY_DEPTH_PATH,
} from '@/imports/lib/DTO/supplier-sku-purchases/get-supplier-sku-category-depth.dto'

import type { SupplierFootprintYearlyBreakdown } from '@/server/services/@types/supplier-footprint.service.types'

import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useSuppliersApi = (http: AxiosInstance) => ({
  getSuppliers(params: GetSuppliersDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSuppliersDTORes>(GET_SUPPLIERS_PATH, { params, headers: { 'x-org-id': activeOrgId } })
  },

  getSuppliersForCohorts(params: GetSuppliersForCohortsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSuppliersForCohortsDTORes>(GET_SUPPLIERS_FOR_COHORTS_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getUnrequestedSuppliers(params: GetUnrequestedSuppliersDto) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetUnrequestedSuppliersDtoRes>(GET_UNREQUESTED_SUPPLIERS_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getSupplier(supplierId: string, params: GetSupplierDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierDTORes>(replaceUriParams(GET_SUPPLIER_PATH, { supplierId }), {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  acceptInvite(payload: AcceptInviteDTO) {
    const { token, user, organization } = payload

    return http.post<AcceptSupplierInviteDTORes>(replaceUriParams(ACCEPT_SUPPLIER_INVITE_PATH, { token }), {
      user,
      organization,
    })
  },

  getInvite(token: string) {
    return http.get<GetSupplierInviteDTORes>(replaceUriParams(GET_SUPPLIER_INVITE_PATH, { token }))
  },

  resendInvite(inviteId: string) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<ResendInviteDTORes>(replaceUriParams(RESEND_SUPPLIER_INVITE_PATH, { inviteId }), undefined, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  updateInviteEmail(supplierId: string, payload: UpdateInviteEmailDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.patch<UpdateInviteDTORes>(replaceUriParams(UPDATE_INVITE_EMAIL_PATH, { supplierId }), payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  updateSupplierEmails(payload: UpdateSupplierEmailsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.patch<UpdateSupplierEmailsDTORes>(UPDATE_SUPPLIER_EMAILS_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getAssociations(supplierId: string, params: GetSupplierAssociationsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierAssociationsDTORes>(replaceUriParams(GET_SUPPLIER_ASSOCIATIONS_PATH, { supplierId }), {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  editSupplier(supplierId: string, payload: EditSupplierDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.patch<EditSupplierDTORes>(replaceUriParams(EDIT_SUPPLIER_PATH, { supplierId }), payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  mergeSuppliers(payload: MergeSuppliersDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<MergeSuppliersDTORes>(MERGE_SUPPLIERS_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  // deprecated by FF rollout-automated-sku-aggregation
  getPurchases(supplierId: string, params: GetSupplierPurchasesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    const path = replaceUriParams(GET_SUPPLIER_PURCHASES_PATH, { supplierId })

    return http.get<GetSupplierPurchasesDTORes>(path, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getOrgPurchases(params: GetSupplierPurchasesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierPurchasesDTORes>(GET_ORGANISATION_PURCHASES_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getPurchasedSKUsMetadata(params: GetSupplierSKUMetadataDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierSKUMetadataDTORes>(GET_SUPPLIER_SKU_METADATA_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getPurchasesSKUs(params: GetSupplierSKUPurchasesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierSKUPurchasesDTORes>(GET_SUPPLIER_SKU_PURCHASES_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getUnrequestedPurchasedSKUs(params: GetSupplierUnrequestedSKUPurchasesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierUnrequestedSKUPurchasesDTORes>(GET_SUPPLIER_UNREQUESTED_SKU_PURCHASES_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  detachSuppliers(payload: DetachSupplierDto, supplierId: string) {
    const activeOrgId = useOrganizationStore().id || ''
    const path = replaceUriParams(DETACH_SUPPLIERS_PATH, { supplierId })

    return http.patch(path, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  /**
   * @deprecated
   */
  getAssociatedPurchases(params: GetSupplierAssociatedPurchasesDTO, supplierId: string) {
    const activeOrgId = useOrganizationStore().id || ''
    const path = replaceUriParams(GET_SUPPLIER_ASSOCIATED_PURCHASES_PATH, { supplierId })

    return http.get<GetSupplierAssociatedPurchasesDTORes>(path, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  /**
   * @deprecated by rollout-automated-sku-aggregation
   */
  sendRequestKnownOrg(payload: AddSupplierProductRequestDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<AddSupplierKnownProductRequestDTORes>(ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  sendSkuRequestKnownOrg(payload: AddSupplierProductRequestDTOV2) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post(ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_V2_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  /**
   * @deprecated by rollout-automated-sku-aggregation
   */
  sendRequestAndInvite(payload: AddSupplierProductRequestAndInviteDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<AddSupplierKnownProductRequestDTORes>(ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  sendSkuRequestAndInvite(payload: AddSupplierProductRequestAndInviteDTOV2) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post(ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_V2_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  /**
   * @deprecated by rollout-automated-sku-aggregation
   */
  sendRequestAndAssign(payload: AddSupplierProductRequestAndAssignDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post<AddSupplierKnownProductRequestDTORes>(ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  sendSkuRequestAndAssign(payload: AddSupplierProductRequestAndAssignDTOV2) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post(ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_V2_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  addSupplierCorporateRequests(payload: AddSupplierCorporateRequestsDto) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post(ADD_SUPPLIER_CORPORATE_REQUESTS_PATH, payload, {
      headers: {
        'x-org-id': activeOrgId,
      },
    })
  },

  getSupplierCorporateRequests(params: GetSupplierCorporateRequestsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierCorporateRequestsDTORes>(GET_SUPPLIER_CORPORATE_REQUESTS_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getAnnualFootprintReport(params: GetAnnualRespondedDataRequestsPayloadV2) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetAnnualRespondedDataRequestsResponse>('/supplier-footprints/annual-report/v2', {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierPotentialMatches(params: GetSupplierPotentialMatchesDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierPotentialMatchesDTORes>(GET_SUPPLIER_POTENTIAL_MATCHES_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierPotentialMatchesByName(params: GetSupplierPotentialMatchesByNameDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierPotentialMatchesByNameDTORes>(GET_SUPPLIER_POTENTIAL_MATCHES_BY_NAME_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierFootprints({ orgId, supplierFootprintId }: { orgId: string; supplierFootprintId?: string }) {
    return http.get<SupplierFootprintsGetResponse>('/supplier-footprints', {
      params: {
        orgId,
        supplierFootprintId,
      },
    })
  },

  getYearlyBreakdown: useMemoize(() => {
    const orgStore = useOrganizationStore()

    return http.get<{ yearlyBreakdown: SupplierFootprintYearlyBreakdown }>('/supplier-footprints/yearly-breakdown', {
      headers: {
        'x-org-id': orgStore.activeOrganization?.id,
      },
    })
  }),

  createSupplierFootprint(payload: {
    orgId: string
    supplierFootprint: SupplierFootprint
    isDraft: boolean
    footprintRequestId?: string
  }) {
    return http.post<SupplierFootprintUpdateResponse>('/supplier-footprints', payload)
  },

  updateSupplierFootprint(
    payload: {
      orgId: string
      supplierFootprint: SupplierFootprint
      isDraft: boolean
    },
    supplierFootprintId: string,
  ) {
    return http.patch<SupplierFootprintUpdateResponse>(`/supplier-footprints/${supplierFootprintId}`, payload)
  },

  getCohorts(params: GetSupplierCohortsDetailedDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierCohortsDetailedDTORes>(GET_SUPPLIER_COHORTS_DETAILED_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  deleteCohort(cohortId: string) {
    const activeOrgId = useOrganizationStore().id || ''

    const path = replaceUriParams(DELETE_SUPPLIER_COHORT_PATH, { cohortId })

    return http.delete(path, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  createCohorts(payload: CreateSupplierCohortDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.post(CREATE_SUPPLIER_COHORT_PATH, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  editCohorts(cohortId: string, payload: CreateSupplierCohortDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    const path = replaceUriParams(EDIT_SUPPLIER_COHORT_PATH, { cohortId })

    return http.patch(path, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  detachFromCohort(cohortId: string, payload: DetachSupplierCohortDto) {
    const activeOrgId = useOrganizationStore().id || ''

    const path = replaceUriParams(DETACH_SUPPLIER_COHORT_PATH, { cohortId })

    return http.patch(path, payload, {
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierCohorts(params?: GetSupplierCohortsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierCohortsDTORes>(GET_SUPPLIER_COHORTS_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierStats(params: GetSuppliersMetricsDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get(GET_SUPPLIERS_METRICS_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSuppliersSimple(params: GetSuppliersSimpleDTO = {}) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSuppliersSimpleDTORes>(GET_SUPPLIERS_SIMPLE_PATH, {
      params,
      headers: { 'x-org-id': activeOrgId },
    })
  },

  getSupplierSkuCategoryDepth(params: GetSupplierSKUCategoryDepthDTO) {
    const activeOrgId = useOrganizationStore().id || ''

    return http.get<GetSupplierSKUCategoryDepthDTORes>(GET_SUPPLIER_SKU_CATEGORY_DEPTH_PATH, {
      params,
      headers: {
        'x-org-id': activeOrgId,
      },
    })
  },
})
