// eslint-disable-next-line max-classes-per-file
import {
  ArrayMinSize,
  IsArray,
  IsEmail,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  MaxLength,
  ValidateNested,
} from 'class-validator'
import { Type } from 'class-transformer'

import { SUPPLIER_PRODUCT_REQUESTS_CONTROLLER } from './supplier-product-requests.common-vars'

export const MAX_STRING_LENGTH = 10000

export class ProductRequestSkuIdentifier {
  @IsString()
  skuId!: string

  @IsString()
  skuName!: string

  @IsString()
  @IsOptional()
  skuDescription?: string

  @IsUUID()
  skuProductId!: string
}

export class AddSupplierProductRequestDTOV2 {
  @IsUUID()
  orgId!: string

  @IsUUID()
  supplierId!: string

  @IsString()
  @IsOptional()
  @MaxLength(MAX_STRING_LENGTH)
  message?: string

  @IsNumber()
  year!: number

  @IsArray()
  @ArrayMinSize(1)
  @Type(() => ProductRequestSkuIdentifier)
  @ValidateNested()
  productRequestSkus!: ProductRequestSkuIdentifier[]
}

export class AddSupplierProductRequestAndInviteDTOV2 extends AddSupplierProductRequestDTOV2 {
  @IsEmail()
  email!: string
}

export class AddSupplierProductRequestAndAssignDTOV2 extends AddSupplierProductRequestDTOV2 {
  @IsUUID()
  supplierOrgId!: string
}

export const ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_V2_SUB_PATH = 'known/v2'
export const ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_V2_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_KNOWN_PRODUCT_REQUEST_V2_SUB_PATH}`

export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_V2_SUB_PATH = 'assignment/v2'
export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_V2_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_PRODUCT_REQUEST_AND_ASSIGN_V2_SUB_PATH}`

export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_V2_SUB_PATH = 'invite/v2'
export const ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_V2_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${ADD_SUPPLIER_PRODUCT_REQUEST_AND_INVITE_V2_SUB_PATH}`
