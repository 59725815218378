<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="products-icon"
  >
    <path
      d="M10.2858 1.71436V7.42864"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4286 16.7144H16"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 7.42871H19.5714V18.8573C19.5714 19.2362 19.4209 19.5995 19.153 19.8674C18.8851 20.1353 18.5217 20.2859 18.1429 20.2859H2.42857C2.04969 20.2859 1.68633 20.1353 1.41842 19.8674C1.15051 19.5995 1 19.2362 1 18.8573V7.42871Z"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 7.42864L3.14286 3.30007C3.37387 2.83474 3.72738 2.44121 4.16537 2.16181C4.60335 1.8824 5.10923 1.7277 5.62857 1.71436H14.9429C15.4744 1.71464 15.9953 1.8632 16.4471 2.14333C16.8988 2.42346 17.2635 2.82405 17.5 3.30007L19.5714 7.42864"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ProductsIcon',
  })
</script>

<style lang="scss" scoped>
  $module: 'products-icon';

  .#{$module} {
    display: block;
  }
</style>
