export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SORT_ORDER_TABLE_CARD {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}
