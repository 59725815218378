// eslint-disable-next-line max-classes-per-file
import { IsNumber, IsOptional, IsString } from 'class-validator'

import type { BaseSupplierSimple } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSuppliersSimpleDTO {
  @IsOptional()
  @IsString()
  query?: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSuppliersSimpleDTORes = {
  suppliers: BaseSupplierSimple[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIERS_SIMPLE_SUB_PATH = 'simple'
export const GET_SUPPLIERS_SIMPLE_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIERS_SIMPLE_SUB_PATH}`
