import { IsArray, IsUUID, ValidateIf, IsOptional, IsString, IsBoolean } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

// Using POST instead of DELETE because DELETE doesn't support passing arrays in the request body.

export const DELETE_PRODUCTS_SUBPATH = 'delete'
export const DELETE_PRODUCTS_URI = `${PRODUCTS_CONTROLLER}/${DELETE_PRODUCTS_SUBPATH}`

export class DeleteProductDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @ValidateIf(o => !o.selectAll)
  @IsArray()
  productIds?: string[]

  @IsBoolean()
  @IsOptional()
  selectAll?: boolean

  @ValidateIf(o => o.selectAll)
  @IsArray()
  category?: string[]

  @ValidateIf(o => o.selectAll)
  @IsString()
  query?: string
}

export type DeleteProductDTORes = {
  success: boolean
}
