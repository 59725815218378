import type { AxiosInstance } from 'axios'

import { DELETE_PRODUCTS_URI, type DeleteProductDTORes } from '@lib/DTO/products/delete-products.dto'
import {
  DELETE_PRODUCTS_STRUCTURES_URI,
  type DeleteProductStructuresDTO,
  type DeleteProductStructuresDTORes,
} from '@lib/DTO/products/delete-product-structures.dto'

import { DOWNLOAD_PRODUCTS_URI, type DownloadProductDTORes } from '@lib/DTO/products/download-products.dto'
import {
  DOWNLOAD_PRODUCT_STRUCTURES_URI,
  type DownloadProductStructuresDTORes,
} from '@lib/DTO/products/download-product-structures.dto'
import { GET_PRODUCT_URI, type GetProductDTORes } from '@lib/DTO/products/get-product.dto'
import { replaceUriParams } from '@lib/DTO'

import {
  GET_PRODUCT_STRUCTURES_URI,
  GetProductStructuresDTO,
  type GetProductStructuresDTORes,
} from '@lib/DTO/products/get-product-structures.dto'

import {
  GET_PRODUCT_STRUCTURE_INPUTS_URI,
  GetProductStructureInputsDTO,
  type GetProductStructureInputsDTORes,
} from '@lib/DTO/products/get-product-structure-inputs.dto'

import {
  GET_PRODUCT_STRUCTURE_FACILITIES_URI,
  GetProductStructureFacilitiesDTO,
  type GetProductStructureFacilitiesDTORes,
} from '@lib/DTO/products/get-product-structure-facilities.dto'

import {
  GET_PRODUCT_LIST_CATEGORIES_URI,
  type GetProductListCategoriesDTORes,
} from '@/imports/lib/DTO/products/get-product-categories.dto'
import { GET_PRODUCT_LIST_URI, type GetProductListDTORes } from '@/imports/lib/DTO/products/get-products.dto'
import type { ProductsMetaQuery } from '@/imports/@types/ProductList'
import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useProductList = (http: AxiosInstance) => ({
  getProducts(
    orgId: string,
    productsMetaQuery: ProductsMetaQuery,
    startDate?: string,
    endDate?: string,
    withStructureOnly?: boolean,
  ) {
    return http.get<GetProductListDTORes>(GET_PRODUCT_LIST_URI, {
      params: {
        orgId,
        ...productsMetaQuery,
        startDate,
        endDate,
        withStructureOnly,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getProductsCategories(orgId: string) {
    return http.get<GetProductListCategoriesDTORes>(GET_PRODUCT_LIST_CATEGORIES_URI, {
      params: {
        orgId,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getProduct(orgId: string, productId: string) {
    return http.get<GetProductDTORes>(replaceUriParams(GET_PRODUCT_URI, { productId }), {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getProductStructures(params: GetProductStructuresDTO, orgId?: string) {
    const organizationId = orgId || useOrganizationStore().id || ''

    return http.post<GetProductStructuresDTORes>(
      GET_PRODUCT_STRUCTURES_URI,
      { ...params, orgId: organizationId },
      organizationId ? { headers: { 'x-org-id': organizationId } } : undefined, // ✅ Conditionally add headers
    )
  },

  getProductStructureInputs(orgId: string, params: GetProductStructureInputsDTO) {
    return http.get<GetProductStructureInputsDTORes>(GET_PRODUCT_STRUCTURE_INPUTS_URI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getProductStructureFacilities(params: GetProductStructureFacilitiesDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetProductStructureFacilitiesDTORes>(GET_PRODUCT_STRUCTURE_FACILITIES_URI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  deleteProducts(orgId: string, productIds?: string[], selectAll?: boolean, category?: string[], query?: string) {
    return http.post<DeleteProductDTORes>(DELETE_PRODUCTS_URI, {
      orgId,
      productIds,
      selectAll,
      category,
      query,
    })
  },

  downloadProducts(orgId: string, productIds?: string[], selectAll?: boolean, category?: string[], query?: string) {
    return http.post<DownloadProductDTORes>(DOWNLOAD_PRODUCTS_URI, {
      orgId,
      productIds,
      selectAll,
      category,
      query,
    })
  },

  deleteProductStructures(params: DeleteProductStructuresDTO) {
    return http.post<DeleteProductStructuresDTORes>(DELETE_PRODUCTS_STRUCTURES_URI, params)
  },

  downloadProductStructures({
    productIds,
    downloadDefaultTemplate,
    pageNo,
    perPage,
    sortField,
    sortOrder,
    startDate,
    endDate,
    structureIds,
    selectedStructures,
    allStructuresSelected,
    structureFacilities,
    structureQuery,
    productQuery,
    productCategory,
    allProductsSelected,
  }: {
    downloadDefaultTemplate?: boolean
    pageNo: number
    perPage: number
    sortField: string
    sortOrder: string
    startDate: string
    endDate: string
    structureIds?: string[]
    selectedStructures?: string[]
    allStructuresSelected: boolean
    structureFacilities?: string[]
    structureQuery?: string
    productQuery?: string
    productCategory?: string
    allProductsSelected: boolean
    productIds?: string[]
  }) {
    const orgId = useOrganizationStore().id || ''

    return http.post<DownloadProductStructuresDTORes>(DOWNLOAD_PRODUCT_STRUCTURES_URI, {
      orgId,
      productIds,
      downloadDefaultTemplate,
      pageNo,
      perPage,
      sortField,
      sortOrder,
      startDate,
      endDate,
      structureIds,
      selectedStructures,
      allStructuresSelected,
      structureFacilities,
      structureQuery,
      productQuery,
      productCategory,
      allProductsSelected,
    })
  },
})
