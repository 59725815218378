import { IsUUID, IsString, ValidateIf, IsOptional } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const CREATE_PACT_REQUEST_SUBPATH = ''
export const CREATE_PACT_REQUEST_URI = `${PACT_REQUEST_CONTROLLER}${CREATE_PACT_REQUEST_SUBPATH}`

export class CreatePactRequestDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  connectionId!: string

  @ValidateIf(o => !o.invoiceId)
  @IsString()
  productId!: string

  @ValidateIf(o => !o.productId)
  @IsString()
  invoiceId!: string

  @ValidateIf(o => !o.productId)
  @IsString()
  invoiceLineItem!: string

  @ValidateIf(o => !o.productId)
  @IsOptional()
  @IsString()
  invoiceDate?: string

  @IsString()
  @IsOptional()
  comment?: string
}

export type CreatePactRequestDTORes = object
