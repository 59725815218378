// eslint-disable-next-line max-classes-per-file
import { IsEnum, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { ENUM_ACTIVITY_AREAS, ENUM_EF_SOURCES } from '../../../@enums/product-modelling.enums'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const CREATE_CUSTOM_MODEL_INPUT_SUBPATH = '/inputs/custom'
export const CREATE_CUSTOM_MODEL_INPUT_URI = `${PRODUCT_MODELLING_CONTROLLER}${CREATE_CUSTOM_MODEL_INPUT_SUBPATH}`

export class CreateCustomProductModelInputDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  productModellingId!: string

  @IsString()
  activityArea!: ENUM_ACTIVITY_AREAS

  @IsNumber()
  emissionFactor!: number

  @IsString()
  inputName!: string

  @IsString()
  @IsOptional()
  supplierName?: string

  @IsString()
  @IsOptional()
  emissionFactorUnit?: string

  @IsEnum(ENUM_EF_SOURCES)
  @IsOptional()
  source?: ENUM_EF_SOURCES

  @IsString()
  @IsOptional()
  linkToSource?: string
}

export type CreateCustomProductModelInputDTORes = {
  inputId: string
}

export interface ICreateCustomModelInputArgs extends CreateCustomProductModelInputDTOReq {}
