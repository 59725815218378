import Level from '@components/Level.vue'

import DefaultLayout from '@/imports/ui/layouts/DefaultLayout.vue'
import DefaultNoPaddingLayout from '@/imports/ui/layouts/DefaultNoPaddingLayout.vue'
import NoSidebarLayout from '@/imports/ui/layouts/NoSidebarLayout.vue'
import NoSidebarOrNavbarLayout from '@/imports/ui/layouts/NoSidebarOrNavbarLayout.vue'
import NoHeaderLayout from '@/imports/ui/layouts/NoHeaderLayout.vue'
import EmptyLayout from '@/imports/ui/layouts/EmptyLayout.vue'
import SplitViewLayout from '@/imports/ui/layouts/SplitViewLayout.vue'
import PactLayout from '@/imports/ui/layouts/PactLayout.vue'
import PdfLayout from '@/imports/ui/layouts/PdfLayout.vue'
// set layout components globally
export const defineVueLayouts = app => {
  app.component('DefaultLayout', DefaultLayout)
  app.component('DefaultNoPaddingLayout', DefaultNoPaddingLayout)
  app.component('NoSidebarLayout', NoSidebarLayout)
  app.component('NoSidebarOrNavbarLayout', NoSidebarOrNavbarLayout)
  app.component('NoHeaderLayout', NoHeaderLayout)
  app.component('Level', Level)
  app.component('EmptyLayout', EmptyLayout)
  app.component('SplitViewLayout', SplitViewLayout)
  app.component('PactLayout', PactLayout)
  app.component('PdfLayout', PdfLayout)
}
