import { IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODEL_NAMES_SUBPATH = '/names'
export const GET_PRODUCT_MODEL_NAMES_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODEL_NAMES_SUBPATH}`

export class GetProductModelNamesDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type GetProductModelNamesDTORes = {
  initiativeNames: string[]
}
