export enum ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UNCHANGED = 'UNCHANGED',
}

export enum ENUM_ACTIVITY_AREAS {
  MATERIALS = 'Materials',
  PACKAGING = 'Packaging',
  WASTE_GENERATION = 'Waste Generation',
  UTILITY_USE = 'Utility Use',
}

export enum ENUM_EF_SOURCES {
  SUPPLIER = 'From supplier documentation',
  ESTIMATE = 'Our own estimate',
  ACADEMIA = 'From academia',
  OTHER = 'Other source',
}
