// eslint-disable-next-line max-classes-per-file
import { IsUUID } from 'class-validator'

import { Expose, Transform } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { ENUM_EF_SOURCES, ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION } from '../../../@enums/product-modelling.enums'

import type { IProductModel } from '../../../@types/product-modelling/product-modelling.types'

import type { IProductModelInput } from '../../../@types/product-modelling/product-modelling-inputs.types'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODEL_SUBPATH = '/:initiativeId'
export const GET_PRODUCT_MODEL_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODEL_SUBPATH}`

export class GetProductModelDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  initiativeId!: string
}

export class ProductModelEntity implements Partial<IProductModel> {
  @Expose()
  id: string

  @Expose()
  name: string

  @Expose()
  facilityName: string

  @Expose({ name: 'pcf_start_date' })
  startDate: string

  @Expose({ name: 'declared_unit' })
  declaredUnit: string

  @Expose({ name: 'declared_unit_weight' })
  declaredUnitWeight: number

  @Expose({ name: 'declared_unit_weight_unit' })
  declaredUnitWeightUnit: string

  @Expose({ name: 'emission_change_factor' })
  @Transform(({ value }) => (value === null ? 0 : Number(value)))
  emissionChangeFactor: number

  @Expose({ name: 'current_emissions' })
  @Transform(({ value }) => (value === null ? 0 : Number(value)))
  currentEmissions: number

  @Expose({ name: 'calculated_emissions' })
  @Transform(({ value }) => (value === null ? 0 : Number(value)))
  calculatedEmission: number

  @Expose()
  @Transform(({ obj }) => {
    const original = obj.emission_change_factor ?? 0
    const calculated = obj.calculated_emissions ?? 0
    return Number(calculated) - Number(original)
  })
  calculatedEmissionChange: number

  @Expose({ name: 'unit_weight_after_remodelling' })
  unitWeightAfterRemodelling: number

  @Expose({ name: 'products_produced' })
  productsProduced: number
}

export class ProductModelInputEntity implements Partial<IProductModelInput> {
  @Expose()
  id = ''

  @Expose()
  activityArea = ''

  @Expose()
  activity = ''

  @Expose()
  supplierName = ''

  @Expose()
  emissionFactor = 0

  @Expose()
  emissionFactorUnit = ''

  @Expose()
  quantity = 0

  @Expose()
  quantityUnit = ''

  @Expose()
  remodelledQuantity = 0

  @Expose()
  isCustomInput = false

  @Expose()
  inputAction: ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION

  @Expose()
  accuracyScore: number

  @Expose()
  originalInputEmissions: number

  @Expose()
  reportUuid: string

  @Expose()
  source: ENUM_EF_SOURCES

  @Expose()
  linkToSource: string
}

export interface GetProductModelDTORes {
  model: ProductModelEntity | null

  hasSourceDataUpdate: boolean

  dateOfUpdate: string

  inputs: ProductModelInputEntity[]
}
