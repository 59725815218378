import { IsUUID } from 'class-validator'

import { SUPPLIER_COHORTS_CONTROLLER } from './supplier-cohorts.common-vars'

export class DetachSupplierCohortDto {
  @IsUUID()
  supplierId!: string
}

export const DETACH_SUPPLIER_COHORT_SUB_PATH = ':cohortId/detach'
export const DETACH_SUPPLIER_COHORT_PATH = `${SUPPLIER_COHORTS_CONTROLLER}/${DETACH_SUPPLIER_COHORT_SUB_PATH}`
