<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 3C2.775 3 3 3.225 3 3.5V14.5C3 15.3281 3.67188 16 4.5 16H17.5C17.775 16 18 16.225 18 16.5C18 16.775 17.775 17 17.5 17H4.5C3.11875 17 2 15.8813 2 14.5V3.5C2 3.225 2.225 3 2.5 3ZM6.5 10C6.775 10 7 10.225 7 10.5V13.5C7 13.775 6.775 14 6.5 14C6.225 14 6 13.775 6 13.5V10.5C6 10.225 6.225 10 6.5 10ZM10 6.5V13.5C10 13.775 9.775 14 9.5 14C9.225 14 9 13.775 9 13.5V6.5C9 6.225 9.225 6 9.5 6C9.775 6 10 6.225 10 6.5ZM12.5 8C12.775 8 13 8.225 13 8.5V13.5C13 13.775 12.775 14 12.5 14C12.225 14 12 13.775 12 13.5V8.5C12 8.225 12.225 8 12.5 8ZM16 5.5V13.5C16 13.775 15.775 14 15.5 14C15.225 14 15 13.775 15 13.5V5.5C15 5.225 15.225 5 15.5 5C15.775 5 16 5.225 16 5.5Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: string
    height?: string
    fillColor?: string
  }

  withDefaults(defineProps<Props>(), {
    width: '20',
    height: '20',
    fillColor: '#3C3D3D',
  })
</script>
