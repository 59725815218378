export enum CORPORATE_INITIATIVE_STATUS {
  LIVE = 'live',
  DRAFT = 'draft',
}

export enum GET_CORPORATE_INITIATIVES_SORT_FIELDS {
  NAME = 'name',
  ACTIVITY_NAME = 'activityName',
  SAVED_EMISSIONS = 'savedEmissions',
  PERCENTAGE_REDUCTION = 'percentageReduction',
  STATUS = 'status',
}

export enum GET_CORPORATE_INITIATIVES_CHART_VIEW {
  TOTAL_EMISSIONS = 'totalEmissions',
  ACTIVITY = 'activity',
  INITIATIVES = 'initiatives',
}
