import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const GET_PACT_PRODUCTS_SUBPATH = 'products'
export const GET_PACT_PRODUCTS_URI = `${PACT_REQUEST_CONTROLLER}/${GET_PACT_PRODUCTS_SUBPATH}`

export type PactProducts = {
  id: string
  name: string
}

export class GetPactProductsDTOReq {}

export type GetPactProductsDTORes = {
  products: PactProducts[]
}
