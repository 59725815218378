import type { AxiosInstance } from 'axios'

import { GET_ONBOARDING_PROGRESS_URI } from '@lib/DTO/pcf-onboarding/pcf-onboarding-progress.dto'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import type { GetProductFootprintStepsResponse } from '@/imports/@types/DataRequest'

export const useProductCarbonFootprintOnboardingApi = (http: AxiosInstance) => ({
  getProductFootprintStepsData() {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetProductFootprintStepsResponse>(GET_ONBOARDING_PROGRESS_URI, {
      params: {},
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
