import { IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const DELETE_PRODUCT_MODELLING_SUBPATH = '/:initiativeId'
export const DELETE_PRODUCT_MODELLING_URI = `${PRODUCT_MODELLING_CONTROLLER}${DELETE_PRODUCT_MODELLING_SUBPATH}`

export class DeleteProductModellingDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  initiativeId!: string
}

export type DeleteProductModellingDTORes = {
  initiativeId: string
}
