import { IsNumber, IsOptional, IsUUID } from 'class-validator'

import { SUPPLIER_PRODUCT_REQUESTS_CONTROLLER } from './supplier-product-requests.common-vars'

export class GetPCFEmissionsCoverageV2Dto {
  @IsNumber()
  year!: number

  @IsUUID()
  @IsOptional()
  cohortId?: string
}

export const GET_PCF_EMISSIONS_COVERAGE_V2_SUB_PATH = 'emissions-coverage/v2'
export const GET_PCF_EMISSIONS_COVERAGE_V2_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${GET_PCF_EMISSIONS_COVERAGE_V2_SUB_PATH}`
