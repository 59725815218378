// eslint-disable-next-line max-classes-per-file
import { IsUUID } from 'class-validator'

import { Expose, Transform } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import {
  ENUM_ACTIVITY_AREAS,
  ENUM_EF_SOURCES,
  ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION,
} from '../../../@enums/product-modelling.enums'

import type { IProductModelInput } from '../../../@types/product-modelling/product-modelling-inputs.types'

import { DEFAULT_TOTAL_AMOUNT_UNIT, DEFAULT_TOTAL_EMISSIONS_UNIT } from '../../constants/corporateInitiativesConstants'

import type { IProductModel } from '../../../@types/product-modelling/product-modelling.types'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const CREATE_PRODUCT_MODEL_INPUT_SUBPATH = '/inputs'
export const CREATE_PRODUCT_MODEL_INPUT_URI = `${PRODUCT_MODELLING_CONTROLLER}${CREATE_PRODUCT_MODEL_INPUT_SUBPATH}`

export class CreateProductModelInputDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  reportId!: string

  @IsUUID()
  productModellingId!: string
}

export type CreateProductModelInputDTORes = {
  inputId: string
}

export class PostProductModelInputEntity implements Partial<IProductModelInput> {
  @Expose({ name: 'productModellingId' })
  productModellingId = ''

  @Expose()
  @Transform(() => 'this field will be deleted soon')
  productCode = ''

  @Expose()
  @Transform(() => 'this field will be deleted soon')
  productName = ''

  @Expose()
  activityArea: ENUM_ACTIVITY_AREAS

  @Expose()
  activity = ''

  @Expose()
  supplierName = ''

  @Expose()
  emissionFactor = 0

  @Expose()
  categoryName: string

  @Expose()
  @Transform(() => 0)
  quantity: number

  @Expose()
  @Transform(({ value }) => value ?? DEFAULT_TOTAL_AMOUNT_UNIT)
  quantityUnit: string

  @Expose()
  @Transform(() => 0)
  remodelledQuantity: number

  @Expose()
  @Transform(({ value }) => value ?? DEFAULT_TOTAL_EMISSIONS_UNIT)
  emissionFactorUnit: string

  @Expose()
  @Transform(({ value }) => value ?? false)
  isCustomInput: boolean

  @Expose()
  @Transform(({ value }) => value ?? ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION.ADD)
  inputAction: ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION

  @Expose()
  @Transform(({ value }) => value ?? ENUM_EF_SOURCES.ESTIMATE)
  source: ENUM_EF_SOURCES

  @Expose()
  @Transform(({ value }) => value ?? null)
  linkToSource: string

  @Expose()
  @Transform(() => 0)
  originalInputEmissions: number

  @Expose()
  @Transform(() => 0)
  calculatedInputEmissions: number

  @Expose()
  reportUuid: string
}

export enum CRUD_ACTIONS {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'destroy',
}

export interface ICreateInputAndRecalculateModel {
  productModel: IProductModel
  action: CRUD_ACTIONS
  inputId?: string
  inputData?: Partial<IProductModelInput>
}
