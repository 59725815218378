import type { AxiosInstance } from 'axios'
import {
  GET_PACT_REQUEST_CONNECTIONS_URI,
  type GetPactRequestConnectionsDTORes,
} from '@lib/DTO/pact-request/pact-request-connections-get.dto'
import { UPDATE_PACT_REQUEST_CONNECTIONS_URI } from '@lib/DTO/pact-request/pact-request-connections-update.dto'
import {
  CREATE_PACT_REQUEST_CONNECTIONS_URI,
  CreatePactRequestConnectionsDTOReq,
  type CreatePactRequestConnectionsDTORes,
} from '@lib/DTO/pact-request/pact-request-connections-create.dto'

import { replaceUriParams } from '@lib/DTO'
import { GET_PACT_REQUESTS_URI, type GetPactRequestsDTORes } from '@lib/DTO/pact-request/pact-requests-get.dto'

import {
  CREATE_PACT_REQUEST_URI,
  CreatePactRequestDTOReq,
  type CreatePactRequestDTORes,
} from '@lib/DTO/pact-request/pact-requests-create.dto'

import { GET_PACT_REQUEST_URI, type GetPactRequestDTORes } from '@lib/DTO/pact-request/pact-request-get.dto'

import {
  FULFILL_PACT_REQUEST_URI,
  FulfillPactRequestDTOReq,
  type FulfillPactRequestDTORes,
} from '@lib/DTO/pact-request/pact-requests-fulfill.dto'

import { GET_PACT_PRODUCTS_URI, type GetPactProductsDTORes } from '@lib/DTO/pact-request/pact-products-get.dto'

import { PACT_REQUEST_TYPES } from '@/imports/@types/pact/PactRequests'

export const usePactRequestApi = (http: AxiosInstance) => ({
  getConnections(orgId: string) {
    return http.get<GetPactRequestConnectionsDTORes>(GET_PACT_REQUEST_CONNECTIONS_URI, {
      params: { orgId },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  createConnection({ orgId, label, clientId, clientSecret, clientSubPath }: CreatePactRequestConnectionsDTOReq) {
    return http.post<CreatePactRequestConnectionsDTORes>(
      CREATE_PACT_REQUEST_CONNECTIONS_URI,
      {
        orgId,
        label,
        clientId,
        clientSecret,
        clientSubPath,
      },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  updateConnection(orgId: string, id: string, payload: Omit<CreatePactRequestConnectionsDTOReq, 'orgId'>) {
    return http.patch<CreatePactRequestConnectionsDTORes>(
      replaceUriParams(UPDATE_PACT_REQUEST_CONNECTIONS_URI, { id }),
      payload,
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  getRequest(orgId: string, id: string) {
    return http.get<GetPactRequestDTORes>(replaceUriParams(GET_PACT_REQUEST_URI, { id }), {
      params: { orgId, id },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getRequests(orgId: string, requestType: PACT_REQUEST_TYPES) {
    return http.get<GetPactRequestsDTORes>(GET_PACT_REQUESTS_URI, {
      params: { orgId, requestType },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  createRequest({ orgId, connectionId, productId, invoiceId, invoiceLineItem, comment }: CreatePactRequestDTOReq) {
    return http.post<CreatePactRequestDTORes>(
      CREATE_PACT_REQUEST_URI,
      {
        orgId,
        connectionId,
        productId,
        invoiceId,
        invoiceLineItem,
        comment,
      },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  fulfillRequest({ orgId, requestId, productId }: FulfillPactRequestDTOReq) {
    return http.post<FulfillPactRequestDTORes>(
      replaceUriParams(FULFILL_PACT_REQUEST_URI, { id: requestId }),
      {
        orgId,
        requestId,
        productId,
      },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  getProducts(orgId: string) {
    return http.get<GetPactProductsDTORes>(GET_PACT_PRODUCTS_URI, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
