import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import type { Product } from '../../../@types/ProductList'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_SUBPATH = ':productId'
export const GET_PRODUCT_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_SUBPATH}`

export class GetProductDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  productId!: string
}

export type GetProductDTORes = Product
