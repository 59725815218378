import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'

import { useAuditLogApi } from '@services/apiNamespaces/auditLog/auditLog.api'

import { usePactRequestApi } from '@services/apiNamespaces/pact/pact.api'

import { FileUploadsApi } from './apiNamespaces/fileUploads.api'
import { ProductsApi } from './apiNamespaces/products.api'
import { ProductModellingApi } from './apiNamespaces/productModelling.api'
import { InitiativesApi } from './apiNamespaces/initiatives.api'
import { useSupplierProductsApi } from './apiNamespaces/supplierProducts.api'
import { useConfigApi } from './apiNamespaces/config.api'
import { useOrganizationApi } from './apiNamespaces/organization.api'
import { useReportsApi } from './apiNamespaces/reports.api'
import { useUserApi } from './apiNamespaces/user.api'
import { DataSourceApi } from './apiNamespaces/dataSource.api'
import { useInviteApi } from './apiNamespaces/invite.api'
import { useMessagesApi } from './apiNamespaces/message.api'
// skipcq: JS-W1029 - deprecated but in use
import { useRecommendationsApi } from './apiNamespaces/recommendations.api'
import { useCustomDashboardApi } from './apiNamespaces/customDashboards'
import { useOrganizationStructureApi } from './apiNamespaces/organizationStructure/v2/organizationStructure.api'
import { useOrganizationPropertiesApi } from './apiNamespaces/organizationProperties/v2/organizationProperties.api'
import { useFacilitiesPropertiesApi } from './apiNamespaces/facilities/facilities.api'
import { useFacilitiesPropertiesApi as useFacilitiesPropertiesApiV2 } from './apiNamespaces/facilities/facilities.api.v2'
import { useTagTemplateApi } from './apiNamespaces/tagTemplate.api'
import { useOAuthApi } from './apiNamespaces/oauth.api'
import { useSuppliersApi } from './apiNamespaces/suppliers.api'
import { useMapApi } from './apiNamespaces/map.api'
import { useCustomersApi } from './apiNamespaces/customers.api'
import { useLegalEntitiesApi } from './apiNamespaces/legalEntities.api'
import { useDataRequestsApi } from './apiNamespaces/dataRequests.api'
import { useCorporateInitiativesApi } from './apiNamespaces/corporateInitiatives.api'
import { useCorporateFootprintsApi } from './apiNamespaces/corporateFootprints.api'
import { useFileUploadDataStatsApi } from './apiNamespaces/fileUploadDataStats.api'
import { useTargetsApi } from './apiNamespaces/targets.api'
import { useTotalProductQuantityApi } from './apiNamespaces/total-product-quantity.api'
import { useBasicCalculatorApi } from './apiNamespaces/basicCalculator/wizards/basicCalculatorWizard.api'
import { useProductBasicCalculatorApi } from './apiNamespaces/basicCalculator/wizards/productBasicCalculator.api'
import { useIntercomApi } from './apiNamespaces/intercom.api'
import { useFranchisesApi } from './apiNamespaces/franchises.api'
import { useFranchisorsApi } from './apiNamespaces/franchisors.api'
import { useBasicFacilitiesApi } from './apiNamespaces/basicFacilities.api'
import { useDataImporterApi } from './apiNamespaces/dataImporter/dataImporter.api'
import { useProductList } from './apiNamespaces/productList/productList.api'
import { useTagsApi } from './apiNamespaces/tags.api'
import { useExploreDataApi } from './apiNamespaces/exploreData/exploreData.api'
import { useProductCarbonFootprintOnboardingApi } from './apiNamespaces/productCarbonFootprintOnboarding.api'

import ClientApi from '@/client/apiInstance'
import { getApiUrl } from '@/client/helpers'

import type { ApiResponse, ApiResponseInner, ApiResponseOld } from '@/imports/@types/ApiResponse'
import type {
  GetFacilitiesArguments,
  GetFacilitiesForSheetArguments,
  GetFacilityArguments,
} from '@/imports/@types/api/GetFacilitiesArguments'
import type {
  GetFacilitiesResponse,
  GetFacilitiesResponseForSheet,
  GetFacilityResponse,
} from '@/imports/@types/api/GetFacilitiesResponse'
import type { User } from '@/imports/@types/User'
import type { GetDataTrackerUserTasksArguments } from '@/imports/@types/api/GetDataTrackerUserTasksArguments'
import type { DataTrackerUserDataSource } from '@/imports/@types/dataTracker/DataTrackerUserTasks'

const http = axios.create({
  baseURL: `${getApiUrl()}/api/v1`,
})

http.interceptors.response.use(
  resp => resp,
  err => {
    const { response } = err

    if (response?.status === 401) {
      const error = response.data?.error

      if (['unauthorized', 'totp_not_verified'].includes(error)) {
        window.localStorage.removeItem('sessionToken')
        !window.location.href.includes('/login') && window.location.replace('/login')
      }
    }

    return Promise.reject(err)
  },
)

/**
 * Altruistiq API service
 * Exposes methods which reference API endpoints and return typed responses
 *
 * Initial integration takes the form of a wrapper around our existing api client
 * which types the responses.
 */
export const Api = {
  updateAuthToken() {
    http.defaults.headers.common.Authorization = `Bearer ${ClientApi.getAuthToken()}`
  },

  call<T>(endpoint: string, payload?: unknown): ApiResponseOld<T> {
    return ClientApi.call(endpoint, payload)
  },

  get<T>(endpoint: string, config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return http.get<T, AxiosResponse<T>>(endpoint, config)
  },

  // Migrated to class structure
  fileUploads: new FileUploadsApi(http),
  products: new ProductsApi(http),
  initiatives: new InitiativesApi(http),
  productModelling: new ProductModellingApi(http),

  // Object structure
  supplierProducts: useSupplierProductsApi(http),
  config: useConfigApi(http),
  dataSource: new DataSourceApi(http),
  invite: useInviteApi(http),
  messages: useMessagesApi(http),
  organization: useOrganizationApi(http),
  reports: useReportsApi(http),
  user: useUserApi(http),
  recommendations: useRecommendationsApi(http),
  organizationStructure: useOrganizationStructureApi(http),
  organizationProperty: useOrganizationPropertiesApi(http),
  facilities: useFacilitiesPropertiesApi(http),
  facilitiesV2: useFacilitiesPropertiesApiV2(http),
  tagTemplate: useTagTemplateApi(http),
  customDashboards: useCustomDashboardApi(http),
  corporateFootprints: useCorporateFootprintsApi(http),
  oauth: useOAuthApi(http),
  map: useMapApi(http),
  suppliers: useSuppliersApi(http),
  customers: useCustomersApi(http),
  legalEntities: useLegalEntitiesApi(http),
  dataRequests: useDataRequestsApi(http),
  corporateInitiatives: useCorporateInitiativesApi(http),
  fileUploadDataStats: useFileUploadDataStatsApi(http),
  targets: useTargetsApi(http),
  totalProductQuantity: useTotalProductQuantityApi(http),
  basicCalculator: useBasicCalculatorApi(http),
  productBasicCalculator: useProductBasicCalculatorApi(http),
  intercom: useIntercomApi(http),
  franchises: useFranchisesApi(http),
  auditLog: useAuditLogApi(http),
  franchisors: useFranchisorsApi(http),
  basicFacilities: useBasicFacilitiesApi(http),
  dataImporter: useDataImporterApi(http),
  productList: useProductList(http),
  tags: useTagsApi(http),
  pactRequest: usePactRequestApi(http),
  exploreData: useExploreDataApi(http),
  productCarbonFootprintOnboarding: useProductCarbonFootprintOnboardingApi(http),

  /** The endpoint for the facilities table (facilities v2) */
  getFacilities({ orgId, year }: GetFacilitiesArguments): Promise<ApiResponse<GetFacilitiesResponse>> {
    return http.get('/facility/getallfororganization', {
      params: {
        orgId,
        year,
      },
    })
  },

  /** The endpoint for a single facility (facilities v2) */
  getFacility({ orgId, facilityId, year }: GetFacilityArguments): Promise<ApiResponse<GetFacilityResponse>> {
    return http.get('/facility/get', {
      params: {
        orgId,
        facilityId,
        year,
      },
    })
  },

  /** The endpoint for the facilities sheet (facilities v1) */
  getFacilitiesForSheet({
    orgId,
    year,
    targetOrgId,
  }: GetFacilitiesForSheetArguments): Promise<ApiResponse<GetFacilitiesResponseForSheet>> {
    return http.get('/facility/list', {
      params: {
        orgId,
        year,
        targetOrgId,
      },
    })
  },

  getActivityById(activityId: number): Promise<ApiResponse<{ activityId: number; activityName: string }>> {
    return http.get('/config/getActivityById', {
      params: {
        activityId,
      },
    })
  },

  getSuperUsers() {
    return http.get<ApiResponseInner<User[]>>('/user/superusers/list')
  },

  getDataTrackerUserTasks(
    payload: GetDataTrackerUserTasksArguments,
  ): Promise<ApiResponse<DataTrackerUserDataSource[]>> {
    return http.get('/dataSource/getUserTasks', {
      params: {
        ...payload,
      },
    })
  },

  deleteOneSchemaFile(embedId: number) {
    return http.post('/data-importer/delete-one-schema-upload', {
      embed_id: embedId,
    })
  },
}

Api.updateAuthToken()
