// eslint-disable-next-line max-classes-per-file
import { IsUUID } from 'class-validator'

import { Expose, Transform } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'
import { PostProductModelInputEntity } from './post-input.dto'

export const UPDATE_PRODUCT_MODEL_INPUT_SNAP_SUBPATH = '/inputs/:inputId'
export const UPDATE_PRODUCT_MODEL_INPUT_SNAP_URI = `${PRODUCT_MODELLING_CONTROLLER}${UPDATE_PRODUCT_MODEL_INPUT_SNAP_SUBPATH}`

export class UpdateInputSnapshotDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  inputId!: string

  @IsUUID()
  reportId!: string
}

export type UpdateInputSnapshotDTORes = {
  inputId: string
}

export class UpdateInputSnapshotEntity extends PostProductModelInputEntity {
  @Expose()
  @Transform(({ obj }) => obj.remodelledQuantity)
  remodelledQuantity: number

  @Expose()
  @Transform(({ obj }) => obj.remodelledQuantity * obj.emissionFactor)
  calculatedInputEmissions: number
}
