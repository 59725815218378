import { IsArray, IsUUID, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { ValidateDTO } from '..'

import {
  BASIC_PCF_CALCULATIONS_CONTROLLER,
  BasicCalculationFacilityForm,
} from '../../../lib/DTO/basic-pcf-calculations/basic-pcf-calculations-cache.common-vars'

export const UPDATE_BASIC_PCF_CACHE_SUBPATH = 'cache/:cacheId'
export const UPDATE_BASIC_PCF_CACHE_URI = `${BASIC_PCF_CALCULATIONS_CONTROLLER}/${UPDATE_BASIC_PCF_CACHE_SUBPATH}`

export class UpdateBasicPCFCacheDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  cacheId!: string

  @IsArray()
  @ValidateNested()
  @Type(() => BasicCalculationFacilityForm)
  facilitiesForms!: BasicCalculationFacilityForm[]
}

export type UpdateBasicPCFCacheRes = {
  success: true
}
