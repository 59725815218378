import { replaceUriParams } from '@lib/DTO'
import type { AxiosInstance } from 'axios'

import {
  GET_PRODUCT_MODELS_PRODUCTS_URI,
  type GetProductModellingProductsDTOReq,
  type GetProductModellingProductsDTORes,
} from '@lib/DTO/product-modelling/get-products.dto'

import {
  GET_PRODUCT_MODELS_PRODUCT_CATEGORIES_URI,
  type GetProductModellingProductCategoriesDTOReq,
  type GetProductModellingProductCategoriesDTORes,
} from '@lib/DTO/product-modelling/get-product-categories.dto'

import {
  GET_PRODUCT_MODELS_SNAPSHOTS_URI,
  type GetProductModellingSnapshotsDTOReq,
  type GetProductModellingSnapshotsDTORes,
} from '@lib/DTO/product-modelling/get-product-snapshots.dto'

import {
  GET_PRODUCT_MODELS_SNAPSHOT_FACILITIES_URI,
  GetProductModellingSnapshotFacilitiesDTOReq,
  type GetProductModellingSnapshotFacilitiesDTORes,
} from '@lib/DTO/product-modelling/get-product-facilities.dto'

import {
  GET_PRODUCT_MODELLING_URI,
  GetProductModellingDTOReq,
  type GetProductModellingDTORes,
} from '@lib/DTO/product-modelling/get-product-modelling.dto'

import {
  CreateProductModellingDTOReq,
  POST_PRODUCT_MODELLING_URI,
  type CreateProductModellingDTORes,
} from '@lib/DTO/product-modelling/post-product-modelling.dto'

import {
  GET_PRODUCT_MODELLING_FACILITIES_URI,
  GetProductModellingFacilitiesDTOReq,
  type GetProductModellingFacilitiesDTORes,
} from '@lib/DTO/product-modelling/get-product-modelling-facilities.dto'

import {
  GET_PRODUCT_MODEL_URI,
  type GetProductModelDTOReq,
  type GetProductModelDTORes,
} from '@lib/DTO/product-modelling/get-product-model.dto'
import {
  GET_PRODUCT_SNAPSHOT_INPUTS_URI,
  GetProductSnapshotInputsDTOReq,
  type GetProductSnapshotInputsDTORes,
} from '@lib/DTO/product-modelling/get-product-snapshot-inputs.dto'

import {
  CREATE_PRODUCT_MODEL_INPUT_URI,
  type CreateProductModelInputDTOReq,
  type CreateProductModelInputDTORes,
} from '@lib/DTO/product-modelling/post-input.dto'

import {
  UPDATE_PRODUCT_MODEL_INPUT_URI,
  type UpdateProductModelInputDTOReq,
  type UpdateProductModelInputDTORes,
} from '@lib/DTO/product-modelling/update-input.dto'

import {
  DELETE_PRODUCT_MODEL_INPUT_URI,
  DeleteProductModelInputDTOReq,
  type DeleteProductModelInputDTORes,
} from '@lib/DTO/product-modelling/delete-model-input.dto'

import {
  DELETE_PRODUCT_MODELLING_URI,
  DeleteProductModellingDTOReq,
  type DeleteProductModellingDTORes,
} from '@lib/DTO/product-modelling/delete-product-modelling.dto'

import {
  UPDATE_PRODUCT_MODELLING_URI,
  type UpdateProductModellingDTOReq,
  type UpdateProductModellingDTORes,
} from '@lib/DTO/product-modelling/patch-product-modelling.dto'

import {
  CREATE_CUSTOM_MODEL_INPUT_URI,
  type CreateCustomProductModelInputDTOReq,
  type CreateCustomProductModelInputDTORes,
} from '@lib/DTO/product-modelling/post-custom-input.dto'

import {
  GET_PRODUCT_MODEL_NAMES_URI,
  type GetProductModelNamesDTOReq,
  type GetProductModelNamesDTORes,
} from '@lib/DTO/product-modelling/get-product-model-names.dto'

import {
  UPDATE_PRODUCT_MODEL_INPUT_SNAP_URI,
  type UpdateInputSnapshotDTOReq,
  type UpdateInputSnapshotDTORes,
} from '@lib/DTO/product-modelling/update-input-snapshot.dto'

export class ProductModellingApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  getProductModels(params: GetProductModellingDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODELLING_URI, params)

    return this.http.get<GetProductModellingDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getProducts(params: GetProductModellingProductsDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODELS_PRODUCTS_URI, params)

    return this.http.get<GetProductModellingProductsDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getProductCategories(params: GetProductModellingProductCategoriesDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODELS_PRODUCT_CATEGORIES_URI, params)

    return this.http.get<GetProductModellingProductCategoriesDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getSnapshots(params: GetProductModellingSnapshotsDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODELS_SNAPSHOTS_URI, params)

    return this.http.get<GetProductModellingSnapshotsDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getSnapshotInputs(params: GetProductSnapshotInputsDTOReq) {
    const requestURI = GET_PRODUCT_SNAPSHOT_INPUTS_URI

    return this.http.get<GetProductSnapshotInputsDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getSnapshotFacilities(params: GetProductModellingSnapshotFacilitiesDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODELS_SNAPSHOT_FACILITIES_URI, params)

    return this.http.get<GetProductModellingSnapshotFacilitiesDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getModelFacilities(params: GetProductModellingFacilitiesDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODELLING_FACILITIES_URI, params)

    return this.http.get<GetProductModellingFacilitiesDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  createModel(params: CreateProductModellingDTOReq) {
    const requestURI = replaceUriParams(POST_PRODUCT_MODELLING_URI, params)

    return this.http.post<CreateProductModellingDTORes>(requestURI, params, {
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getProductModel(params: GetProductModelDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODEL_URI, params)

    return this.http.get<GetProductModelDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  createProductModelInput(params: CreateProductModelInputDTOReq) {
    const requestURI = replaceUriParams(CREATE_PRODUCT_MODEL_INPUT_URI, params)

    return this.http.post<CreateProductModelInputDTORes>(requestURI, params, {
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  updateProductModelInput(params: UpdateInputSnapshotDTOReq) {
    const requestURI = replaceUriParams(UPDATE_PRODUCT_MODEL_INPUT_SNAP_URI, params)

    return this.http.put<UpdateInputSnapshotDTORes>(requestURI, params, {
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  createProductModelCustomInput(params: CreateCustomProductModelInputDTOReq) {
    const requestURI = replaceUriParams(CREATE_CUSTOM_MODEL_INPUT_URI, params)

    return this.http.post<CreateCustomProductModelInputDTORes>(requestURI, params, {
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  updateProductModelCustomInput(params: UpdateProductModelInputDTOReq) {
    const requestURI = replaceUriParams(UPDATE_PRODUCT_MODEL_INPUT_URI, params)

    return this.http.patch<UpdateProductModelInputDTORes>(requestURI, params, {
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  deleteInput(params: DeleteProductModelInputDTOReq) {
    const requestURI = replaceUriParams(DELETE_PRODUCT_MODEL_INPUT_URI, params)

    return this.http.delete<DeleteProductModelInputDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  deleteInitiative(params: DeleteProductModellingDTOReq) {
    const requestURI = replaceUriParams(DELETE_PRODUCT_MODELLING_URI, params)

    return this.http.delete<DeleteProductModellingDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  renameInitiative(params: UpdateProductModellingDTOReq) {
    const requestURI = replaceUriParams(UPDATE_PRODUCT_MODELLING_URI, params)

    return this.http.patch<UpdateProductModellingDTORes>(requestURI, params, {
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }

  getInitiativeNames(params: GetProductModelNamesDTOReq) {
    const requestURI = replaceUriParams(GET_PRODUCT_MODEL_NAMES_URI, params)

    return this.http.get<GetProductModelNamesDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': params.orgId,
      },
    })
  }
}
