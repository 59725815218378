import { IsNumber, IsOptional, IsUUID } from 'class-validator'

import { SUPPLIER_CORPORATE_REQUESTS_CONTROLLER } from './supplier-corporate-requests.common-vars'

export class GetCCFEmissionsCoverageDto {
  @IsNumber()
  year!: number

  @IsUUID()
  @IsOptional()
  cohortId?: string
}

export const GET_CCF_EMISSIONS_COVERAGE_SUB_PATH = 'emissions-coverage'
export const GET_CCF_EMISSIONS_COVERAGE_PATH = `${SUPPLIER_CORPORATE_REQUESTS_CONTROLLER}/${GET_CCF_EMISSIONS_COVERAGE_SUB_PATH}`
