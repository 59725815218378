// eslint-disable-next-line max-classes-per-file
import { IsArray, IsBoolean, IsEnum, IsNumber, IsOptional, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_TOTAL_PRODUCT_QUANTITY_SORT_FIELD } from '../../../@enums/total-product-quantity.enums'

import { TOTAL_PRODUCT_QUANTITY_CONTROLLER } from './controller.common-vars'

export const GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_SUBPATH = '/'
export const GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_URI = `${TOTAL_PRODUCT_QUANTITY_CONTROLLER}${GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_SUBPATH}`

export class TotalProductQuantityDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsNumber()
  pageNo!: number

  @IsNumber()
  perPage!: number

  @IsEnum(SORT_ORDER)
  sortOrder!: SORT_ORDER

  @IsEnum(GET_TOTAL_PRODUCT_QUANTITY_SORT_FIELD)
  sortField!: GET_TOTAL_PRODUCT_QUANTITY_SORT_FIELD

  @IsArray()
  @IsOptional()
  facilityIds?: string[]

  @IsArray()
  @IsOptional()
  years?: number[]

  @IsBoolean()
  @IsOptional()
  showErrorsOnly?: boolean
}

export type TotalProductQuantity = {
  facilityId: string
  facilityName: string
  year: number
  totalWeightProcessed?: number
  calculatedTotalProductCount: number
  calculatedTotalProductWeight: number
  calculatedTotalProductWeightUnit: string
  id?: string
  totalWeightProcessedUnit?: string
  totalCount: number
  recordCount: number
}

export type TotalProductQuantityDTORes = {
  totalProductQuantities: Omit<TotalProductQuantity, 'recordCount' | 'totalCount'>[]
  meta: {
    totalCount: number // total count of unfiltered records
    count: number // filtered count
    pageNo: number
    perPage: number
  }
}
