// eslint-disable-next-line max-classes-per-file
import { IsEnum, IsNumber, IsOptional, IsString, IsUUID, Min } from 'class-validator'

import { Expose } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODELS_SNAPSHOTS_SUBPATH = '/products/:productId/snapshots'
export const GET_PRODUCT_MODELS_SNAPSHOTS_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODELS_SNAPSHOTS_SUBPATH}`

export enum PRODUCT_MODELLING_SNAPSHOT_SORT_FIELDS {
  FACILITY_NAME = 'facilityName',
  SNAPSHOT_START_DATE = 'snapshotStartDate',
  EMISSIONS_PER_UNIT = 'emissionsPerUnit',
}

export class GetProductModellingSnapshotsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  productId!: string

  @IsString()
  @IsOptional()
  facilityId?: string

  @IsNumber()
  @Min(1)
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @Min(1)
  @IsOptional()
  perPage?: number

  @IsEnum(PRODUCT_MODELLING_SNAPSHOT_SORT_FIELDS)
  @IsOptional()
  sortField?: PRODUCT_MODELLING_SNAPSHOT_SORT_FIELDS

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: SORT_ORDER
}

export class ProductModellingSnapshotEntity {
  @Expose()
  id = ''

  @Expose()
  facilityName = ''

  @Expose()
  facilityId = ''

  @Expose()
  snapshotStartDate = ''

  @Expose()
  emissionsPerUnit = 1.1
}

export interface GetProductModellingSnapshotsDTORes {
  snapshots: ProductModellingSnapshotEntity[]
  meta: {
    pageNo: number
    perPage: number
    count: number
    totalCount: number
  }
}
