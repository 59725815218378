// eslint-disable-next-line max-classes-per-file
import { IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODELLING_FACILITIES_SUBPATH = '/facilities'
export const GET_PRODUCT_MODELLING_FACILITIES_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODELLING_FACILITIES_SUBPATH}`

export class GetProductModellingFacilitiesDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export interface GetProductModellingFacilitiesDTORes {
  facilities: {
    id: string
    name: string
  }[]
}
