import type { InjectionKey, Plugin } from 'vue'

import type { Organization } from '@/imports/@types/Organization'
import type { User } from '@/imports/@types/User'

type VueIntercomOptions = {
  appId: string
}

const init = (options: VueIntercomOptions) => {
  const script = document.createElement('script')
  const body = document.querySelector('body')

  const scriptContent = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${options.appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`

  script.innerText = scriptContent

  body?.appendChild(script)
}

interface IntercomMethods {
  boot(user: User, org: Organization, orgs: string, userHash: string): void
  bootV2(user: User, org: Organization, orgs: string, userHash: string, rootOrgName: string): void
  shutdown(): void
  update(): void
  hide(): void
  show(): void
  showMessages(): void
  showNewMessage(): void
  startTour(tourId: number): void
}

export const INTERCOM_KEY: InjectionKey<IntercomMethods> = Symbol('intercom')

export const VueIntercom: Plugin = {
  install(app, options: VueIntercomOptions) {
    init(options)

    const intercomMethods: IntercomMethods = {
      // Delete boot after rollout of rollout-intercom-changes-to-company-and-company-id
      boot(user: User, org: Organization, orgs: string, userHash: string) {
        try {
          window.Intercom('boot', {
            app_id: options.appId,
            email: user.email,
            name: user.userName,
            user_id: user.id,
            user_role: org.roles?.length ? org.roles[0].role : '',
            user_hash: userHash,
            company: {
              company_id: org.id,
              name: org.name,
            },
            company_client_type: org.configuration.clientType,
            company_contract_scope: org.configuration.contractScope,
            asana_id: org.asanaCompanyId,
            business_units: orgs,
          })
        } catch (err) {
          console.warn('Error when booting Intercom plugin', err)
        }
      },

      // Rename to boot following rollout of rollout-intercom-changes-to-company-and-company-id
      bootV2(user: User, org: Organization, orgs: string, userHash: string, rootOrgName: string) {
        try {
          window.Intercom('boot', {
            app_id: options.appId,
            email: user.email,
            name: user.userName,
            user_id: user.id,
            user_role: org.roles?.length ? org.roles[0].role : '',
            user_hash: userHash,
            company: {
              company_id: org.rootOrgId,
              name: rootOrgName,
            },
            company_client_type: org.configuration.clientType,
            company_contract_scope: org.configuration.contractScope,
            asana_id: org.asanaCompanyId,
            business_units: orgs,
          })
        } catch (err) {
          console.warn('Error when booting Intercom plugin', err)
        }
      },

      shutdown() {
        window.Intercom('shutdown')
      },

      update() {
        window.Intercom('update')
      },

      hide() {
        window.Intercom('hide')
      },

      show() {
        window.Intercom('show')
      },

      showMessages() {
        window.Intercom('showMessages')
      },

      showNewMessage() {
        window.Intercom('showNewMessage')
      },

      startTour(tourId: number) {
        window.Intercom('startTour', tourId)
      },
    }

    app.provide(INTERCOM_KEY, intercomMethods)
  },
}
