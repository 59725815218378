// eslint-disable-next-line max-classes-per-file
import { IsEnum, IsNumber, IsOptional, IsString, IsUUID, Min } from 'class-validator'

import { Expose } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_SNAPSHOT_INPUTS_SUBPATH = '/products/snapshots/inputs'
export const GET_PRODUCT_SNAPSHOT_INPUTS_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_SNAPSHOT_INPUTS_SUBPATH}`

export enum PRODUCT_SNAPSHOT_INPUT_SORT_FIELDS {
  ACTIVITY = 'activity',
  SUPPLIER_NAME = 'supplierName',
  EMISSION_FACTOR = 'emissionFactor',
}

export class GetProductSnapshotInputsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  @IsOptional()
  query?: string

  @IsNumber()
  @Min(1)
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @Min(1)
  @IsOptional()
  perPage?: number

  @IsEnum(PRODUCT_SNAPSHOT_INPUT_SORT_FIELDS)
  @IsOptional()
  sortField?: PRODUCT_SNAPSHOT_INPUT_SORT_FIELDS

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: SORT_ORDER
}

export class ProductSnapshotInputEntity {
  @Expose()
  reportId = ''

  @Expose()
  activity = ''

  @Expose()
  supplierName = ''

  @Expose()
  emissionFactor = 1.1
}

export interface GetProductSnapshotInputsDTORes {
  inputs: ProductSnapshotInputEntity[]
  meta: {
    pageNo: number
    perPage: number
    count: number
    totalCount: number
  }
}
