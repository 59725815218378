import { IsString } from 'class-validator'

import { EXPLORE_DATA_CONTROLLER } from './explore-data.common-vars'

export class GetExploreDataEmbedDto {
  @IsString()
  embedPath!: string

  workbookMenuPosition?: 'top' | 'bottom'
}

export type GetExploreDataEmbedDtoRes = {
  embedUrl: string
}

export const GET_EXPLORE_DATA_EMBED_SUB_PATH = 'embed-path/:embedPath'
export const GET_EXPLORE_DATA_EMBED_PATH = `${EXPLORE_DATA_CONTROLLER}/${GET_EXPLORE_DATA_EMBED_SUB_PATH}`
