// eslint-disable-next-line max-classes-per-file
import { IsString, IsUUID, Length } from 'class-validator'

import { Expose, Transform } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import type { IProductModel } from '../../../@types/product-modelling/product-modelling.types'

import { DEFAULT_TOTAL_AMOUNT_UNIT, DEFAULT_TOTAL_EMISSIONS_UNIT } from '../../constants/corporateInitiativesConstants'

import type { IProductModelInput } from '../../../@types/product-modelling/product-modelling-inputs.types'
import { ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION } from '../../../@enums/product-modelling.enums'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const POST_PRODUCT_MODELLING_SUBPATH = ''
export const POST_PRODUCT_MODELLING_URI = `${PRODUCT_MODELLING_CONTROLLER}${POST_PRODUCT_MODELLING_SUBPATH}`

export class CreateProductModellingDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  @Length(3)
  initiativeName!: string

  @IsString()
  snapshotId!: string

  @IsString()
  facilityId!: string
}

export type CreateProductModellingDTORes = {
  initiativeId: string
}

export interface ICreateProductModelArgs {
  orgId: string
  userId: string
  initiativeName: string
  snapshotId: string
  facilityId: string
}

export enum ENUM_SNAP_FIELDS_TO_CREATE_INPUT {
  activityArea = 'activityArea',
  activity = 'activity',
  supplierName = 'supplierName',
  emissionFactor = 'emissionFactor',
  emissionFactorUnit = 'emissionFactorUnit',
  quantity = 'quantity',
  quantityUnit = 'quantityUnit',
  accuracyScore = 'accuracyScore',
}

export class CreateProductModelEntity implements Partial<IProductModel> {
  @Expose()
  orgId: string

  @Expose({ name: 'facilityUuid' })
  facilityId: string

  @Expose()
  name: string

  @Expose({ name: 'startDate' })
  pcfStartDate: string

  @Expose({ name: 'endDate' })
  pcfEndDate: string

  @Expose()
  productName: string

  @Expose({ name: 'productCode' })
  productId: string

  @Expose({ name: 'snapshotUuid' })
  snapshotId: string

  @Expose({ name: 'unitOfMeasure' })
  @Transform(({ value }) => value || DEFAULT_TOTAL_AMOUNT_UNIT)
  declaredUnit: string

  @Expose({ name: 'weightPerFu' })
  @Transform(({ value }) => value || 0)
  declaredUnitWeight = 0

  @Expose({ name: 'weightPerFuUnit' })
  @Transform(({ value }) => value || DEFAULT_TOTAL_AMOUNT_UNIT)
  declaredUnitWeightUnit: string

  @Expose()
  @Transform(({ obj }) => (obj.weightPerFu == null ? 0 : Number(obj.weightPerFu)))
  unitWeightAfterRemodelling = 0

  @Expose({ name: 'emissionFactor' })
  @Transform(({ value }) => (value === null ? 0 : Number(value)))
  emissionChangeFactor: number

  @Expose({ name: 'emissionQuantity' })
  @Transform(({ value }) => (value === null ? 0 : Number(value)))
  currentEmissions: number

  @Expose()
  @Transform(({ obj }) => (obj.emissionQuantity === null ? 0 : Number(obj.emissionQuantity)))
  calculatedEmissions: number

  @Expose()
  userId: string

  @Expose()
  productsProduced: number

  @Expose()
  reportUuid: string
}

export class CreateUnchangedInputEntity implements Partial<IProductModelInput> {
  @Expose()
  id: string

  @Expose()
  productModellingId = ''

  @Expose()
  activityArea = ''

  @Expose()
  activity = ''

  @Expose()
  supplierName = ''

  @Expose()
  emissionFactor = 0

  @Expose()
  @Transform(({ value }) => value ?? DEFAULT_TOTAL_EMISSIONS_UNIT)
  emissionFactorUnit = ''

  @Expose()
  quantity = 0

  @Expose()
  @Transform(({ value }) => value ?? DEFAULT_TOTAL_AMOUNT_UNIT)
  quantityUnit = ''

  @Expose()
  @Transform(({ obj }) => obj.quantity)
  remodelledQuantity = 0

  @Expose()
  @Transform(({ value }) => value ?? false)
  isCustomInput = false

  @Expose()
  @Transform(({ value }) => value ?? ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION.UNCHANGED)
  inputAction: ENUM_PRODUCT_MODELLING_INPUTS_INPUT_ACTION

  @Expose()
  @Transform(({ value }) => value ?? null)
  accuracyScore: number

  @Expose({ name: 'emissionQuantity' })
  @Transform(({ value }) => (value === null ? 0 : value))
  originalInputEmissions: number

  @Expose()
  @Transform(({ obj }) => obj.emissionQuantity)
  calculatedInputEmissions: number

  @Expose()
  reportUuid = ''
}
