import { IsArray, IsUUID, ValidateIf, IsBoolean, IsOptional, IsString } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const DELETE_PRODUCTS_STRUCTURES_SUBPATH = 'structures/delete'
export const DELETE_PRODUCTS_STRUCTURES_URI = `${PRODUCTS_CONTROLLER}/${DELETE_PRODUCTS_STRUCTURES_SUBPATH}`

export class DeleteProductStructuresDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @ValidateIf(o => !o.allStructuresSelected)
  @IsOptional()
  @IsArray()
  structureIds?: string[]

  @IsBoolean()
  @IsOptional()
  allStructuresSelected?: boolean

  @ValidateIf(o => o.allStructuresSelected)
  @IsArray()
  structureFacilities?: string[]

  @ValidateIf(o => o.allStructuresSelected)
  @IsString()
  @IsOptional()
  structureQuery?: string

  @ValidateIf(o => !o.allStructuresSelected)
  @IsOptional()
  @IsArray()
  productIds?: string[]

  @IsBoolean()
  @IsOptional()
  allProductsSelected?: boolean

  @ValidateIf(o => o.allProductsSelected)
  @IsArray()
  productCategory?: string[]

  @ValidateIf(o => o.allProductsSelected)
  @IsString()
  productQuery?: string

  @IsString()
  startDate!: string

  @IsString()
  endDate!: string
}

export type DeleteProductStructuresDTORes = {
  success: boolean
}
