<template>
  <div>
    <div class="navbar">
      <div class="navbar__container">
        <div class="navbar__left">
          <img
            v-if="logo"
            class="navbar__logo"
            :src="logo"
            :alt="`${organizationName} logo`"
            data-ci="bu-logo"
          />
          <span>{{ organizationName }}</span>
        </div>
        <div class="navbar__right">
          <NavBarMenu
            v-if="organizationName"
            :organization="organizationName"
            :showOrganizationsLink="hasMultipleOrganizations"
            :showAuditLogsLink="hasMultipleOrganizations"
            :user="user"
            @logout="requestLogout"
          />
        </div>
      </div>
    </div>

    <div
      class="page-content"
      data-scroll="page-content"
    >
      <div class="page-content__padding">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import NavBarMenu from '@components/NavBarMenu.vue'
  import { storeToRefs } from 'pinia'

  import { useUserStore } from '@/client/store/user.pinia'
  import { useOrganizationStore } from '@/client/store/organization.pinia'

  const organizationStore = useOrganizationStore()
  const userStore = useUserStore()

  const { hasMultipleOrganizations, name: organizationName, logo } = storeToRefs(organizationStore)
  const { user } = storeToRefs(userStore)

  const requestLogout = async () => {
    await userStore.logout()

    window.location.replace('/login')
  }
</script>

<style lang="scss" scoped>
  .page-content {
    position: absolute;
    top: $topbar-height;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh - 64px); // navbar height is 64px, page-content fills remainder of viewport height
    overflow: auto;

    &__padding {
      padding: $grid-atom * 10 0;
      width: 1200px;
      margin: 0 auto;
    }
  }
  .navbar {
    color: var(--color-text-activity-waste);
    background-color: var(--color-bg-activity-waste-light);
    padding: $grid-atom * 2 $grid-atom * 11;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: $topbar-height;
    border-bottom: 1px solid var(--color-bg-activity-waste);
    z-index: 19;

    &__logo {
      width: 28px;
      height: 28px;
      margin-right: $grid-atom * 2;
      margin-left: $grid-atom * 2;
      border-radius: 6px;
    }
    &__container {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: auto;
      gap: $grid-atom * 4;
    }
  }

  :deep(.reinga-btn--primary) {
    background-color: var(--color-bg-avatar-3) !important;
    border-radius: var(--border-radius-full) !important;
  }
  :deep(.reinga-btn--secondary) {
    border-radius: var(--border-radius-full) !important;
  }
</style>
