import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const GET_PACT_REQUEST_CONNECTIONS_SUBPATH = 'connections'
export const GET_PACT_REQUEST_CONNECTIONS_URI = `${PACT_REQUEST_CONTROLLER}/${GET_PACT_REQUEST_CONNECTIONS_SUBPATH}`

export type PactRequestConnection = {
  id: string
  label: string
  clientId: string
  clientSubPath: string
  oauthClient: { label: string }
}
export class GetPactRequestConnectionsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type GetPactRequestConnectionsDTORes = {
  connections: PactRequestConnection[]
}
