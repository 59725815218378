import type { AxiosInstance } from 'axios'

import {
  GET_TOTAL_PRODUCT_QUANTITY_YEARS_URI,
  type TotalProductQuantityYearsRes,
} from '../../DTO/total-product-quantity/total-product-quantity-years.dto'
import {
  GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_URI,
  type TotalProductQuantityDTOReq,
  type TotalProductQuantityDTORes,
} from '../../DTO/total-product-quantity/total-product-quantity-getForOrg.dto'
import { GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_URI } from '../../DTO/total-product-quantity/total-product-quantity-facility-names.dto'
import { GET_TOTAL_PRODUCT_QUANTITY_ERROR_COUNT_URI } from '../../DTO/total-product-quantity/total-product-quantity-error-count.dto'
import {
  UPDATE_TOTAL_PRODUCT_QUANTITY_URI,
  UpdateTotalProductQuantityRecords,
  type UpdateTotalProductQuantityRes,
} from '../../DTO/total-product-quantity/total-product-quantity-update.dto'
import {
  CREATE_TOTAL_PRODUCT_QUANTITY_URI,
  CreateTotalProductQuantityRecords,
  type CreateTotalProductQuantityRes,
} from '../../DTO/total-product-quantity/total-product-quantity-create.dto'
import {
  DELETE_TOTAL_PRODUCT_QUANTITY_URI,
  type DeleteTotalProductQuantityRecords,
} from '../../DTO/total-product-quantity/total-product-quantity-delete.dto'

export const useTotalProductQuantityApi = (http: AxiosInstance) => ({
  fetchTotalProductQuantityYears(orgId: string) {
    return http.get<TotalProductQuantityYearsRes>(GET_TOTAL_PRODUCT_QUANTITY_YEARS_URI, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  fetchTotalProductQuantities(payload: TotalProductQuantityDTOReq) {
    return http.get<TotalProductQuantityDTORes>(GET_FOR_ORG_TOTAL_PRODUCT_QUANTITY_URI, {
      params: payload,
    })
  },

  getFacilities(orgId: string) {
    return http.get(GET_FACILITY_NAMES_TOTAL_PRODUCT_QUANTITY_URI, {
      params: {
        orgId,
      },
    })
  },

  getTotalProductQuantityErrorCount(orgId: string, startMonth?: string, endMonth?: string) {
    return http.get(GET_TOTAL_PRODUCT_QUANTITY_ERROR_COUNT_URI, {
      params: {
        orgId,
        startMonth,
        endMonth,
      },
    })
  },

  createTotalProductQuantities(
    orgId: string,
    totalProductQuantityPayload: CreateTotalProductQuantityRecords[],
  ): Promise<CreateTotalProductQuantityRes> {
    return http.post(CREATE_TOTAL_PRODUCT_QUANTITY_URI, { records: totalProductQuantityPayload, orgId })
  },

  updateTotalProductQuantities(
    orgId: string,
    totalProductQuantityPayload: UpdateTotalProductQuantityRecords[],
  ): Promise<UpdateTotalProductQuantityRes> {
    return http.patch(UPDATE_TOTAL_PRODUCT_QUANTITY_URI, { records: totalProductQuantityPayload, orgId })
  },

  deleteTotalProductQuantities(orgId: string, totalProductQuantities: DeleteTotalProductQuantityRecords[]) {
    return http.delete(DELETE_TOTAL_PRODUCT_QUANTITY_URI, {
      headers: {
        'x-org-id': orgId,
      },
      data: { records: totalProductQuantities },
    })
  },
})
