import { GET_CORPORATE_INITIATIVES_CHART_VIEW } from '../../@enums/corporate-initiatives.enums'

// Product & Packaging id
export const AVAILABLE_ACTIVITY_IDS = [13]

export const DEFAULT_CHART_VIEW = GET_CORPORATE_INITIATIVES_CHART_VIEW.ACTIVITY

export const SCENARIO_DRAWER_MAX_YEAR = {
  BASELINE: 2050,
  PERIOD_TILL: 2060,
}

export const MAX_AVAILABLE_DATE = new Date(`${SCENARIO_DRAWER_MAX_YEAR.PERIOD_TILL}-12-31`)

export const CURRENCY_SYMBOLS: Record<string, string> = {
  EUR: '€',
  GBP: '£',
  USD: '$',
}

export const SCENARIO_DRAWER_TOOLTIPS = {
  WEIGHTED_AVG_EMISSIONS:
    'The average emissions factor across the selected product category & suppliers. We weight this factor based on the quantity purchased from each.',
  NEW_ACTIVITY_EMISSIONS:
    'This Emission Factor will be used to calculate the impact of your new activity. Make sure the EF covers the relevant lifecycle stages (for most applications this should be Cradle-to-Gate).',
}

export const DEFAULT_TOTAL_AMOUNT_UNIT = 'kg'
export const DEFAULT_TOTAL_EMISSIONS_UNIT = 'kgCO2e'

export const NON_GENERIC_ACTIVITIES = ['Product & Packaging Inputs']

export const WEIGHT_BASED_ERROR_MESSAGE = `cannot_create_initiative_no_weight_based_data`
