import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

export const GET_DATA_SOURCE_FILE_ERRORS_SUBPATH = 'files-errors/data-source/:dataSourceId/file/:fileId'
export const GET_DATA_SOURCE_FILE_ERRORS_URI = `${DATASOURCE_CONTROLLER}/${GET_DATA_SOURCE_FILE_ERRORS_SUBPATH}/`

export class GetFileErrorsReq extends ValidateDTO {
  @IsUUID()
  dataSourceId!: string

  @IsUUID()
  fileId!: string
}

export type GetFileErrorsRes = {
  datasourceUuid: string
  fieldCheckErrors: {
    checkType: string
    columnName: string
    constraintDetails: string[]
    description: string
    errorDetails: unknown
    exampleFailedValues: string[]
    nFailedValues: number
  }[]
  fileFormatErrors: {
    errorCode: string
    errorDetails: string
  }[]
  fileUuid: string
  ingestionErrors: {
    errorCode: string
    errorDetails: string
  }[]
}
