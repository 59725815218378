import { IsString, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const UPDATE_PACT_REQUEST_CONNECTIONS_SUBPATH = 'connections/:id'
export const UPDATE_PACT_REQUEST_CONNECTIONS_URI = `${PACT_REQUEST_CONTROLLER}/${UPDATE_PACT_REQUEST_CONNECTIONS_SUBPATH}`

export class UpdatePactRequestConnectionsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  clientId!: string

  @IsString()
  label!: string

  @IsString()
  clientSecret!: string

  @IsString()
  clientSubPath!: string
}

export type UpdatePactRequestConnectionsDTORes = object
