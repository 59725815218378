import { IsNumber, IsOptional, IsArray } from 'class-validator'

import { SUPPLIER_SKU_PURCHASES_CONTROLLER } from './supplier-sku-purchases.common-vars'

export class GetSupplierSKUCategoryDepthDTO {
  @IsNumber()
  year!: string

  @IsArray()
  @IsOptional()
  supplierIds?: string[]
}

export type GetSupplierSKUCategoryDepthDTORes = {
  categoryDepth: number
}

export const GET_SUPPLIER_SKU_CATEGORY_DEPTH_SUB_PATH = 'category-depth'
export const GET_SUPPLIER_SKU_CATEGORY_DEPTH_PATH = `${SUPPLIER_SKU_PURCHASES_CONTROLLER}/${GET_SUPPLIER_SKU_CATEGORY_DEPTH_SUB_PATH}`
