// eslint-disable-next-line max-classes-per-file
import { IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODELS_PRODUCT_CATEGORIES_SUBPATH = '/products/categories'
export const GET_PRODUCT_MODELS_PRODUCT_CATEGORIES_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODELS_PRODUCT_CATEGORIES_SUBPATH}`

export class GetProductModellingProductCategoriesDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export interface GetProductModellingProductCategoriesDTORes {
  categories: string[]
}
