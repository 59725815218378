import { IsArray, IsUUID, IsBoolean, IsOptional, ValidateIf, IsString } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const DOWNLOAD_PRODUCTS_SUBPATH = 'download'
export const DOWNLOAD_PRODUCTS_URI = `${PRODUCTS_CONTROLLER}/${DOWNLOAD_PRODUCTS_SUBPATH}`

export class DownloadProductDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @ValidateIf(o => !o.selectAll)
  @IsArray()
  productIds?: string[]

  @IsBoolean()
  @IsOptional()
  selectAll?: boolean

  @ValidateIf(o => o.selectAll)
  @IsArray()
  category?: string[]

  @ValidateIf(o => o.selectAll)
  @IsString()
  query?: string
}

export type DownloadProductDTORes = {
  file: { data: Buffer }
  contentType: string
  fileName: string
}
