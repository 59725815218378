import { IsEnum, IsNumber, IsOptional, IsString, IsArray } from 'class-validator'

import type { BaseSupplierSkuPurchase } from '../../../@types/suppliers.types'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { GET_SUPPLIER_SKU_PURCHASES_SORT_FIELD } from '../../../@enums/suppliers.enums'

import { SUPPLIER_SKU_PURCHASES_CONTROLLER } from './supplier-sku-purchases.common-vars'

export class GetSupplierSKUPurchasesDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsOptional()
  @IsArray()
  supplierIds?: string[]

  @IsOptional()
  @IsEnum(GET_SUPPLIER_SKU_PURCHASES_SORT_FIELD)
  sortField?: GET_SUPPLIER_SKU_PURCHASES_SORT_FIELD

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsString()
  @IsOptional()
  searchQuery?: string
}

export type GetSupplierSKUPurchasesDTORes = {
  skuPurchases: BaseSupplierSkuPurchase[]
  meta: {
    pageNo: number
    perPage: number
    totalCount: number
  }
}

export const GET_SUPPLIER_SKU_PURCHASES_SUB_PATH = ''
export const GET_SUPPLIER_SKU_PURCHASES_PATH = `${SUPPLIER_SKU_PURCHASES_CONTROLLER}/${GET_SUPPLIER_SKU_PURCHASES_SUB_PATH}`
