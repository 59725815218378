import { IsArray, IsUUID, ValidateIf, IsBoolean, IsOptional, IsString } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const DOWNLOAD_PRODUCT_STRUCTURES_SUBPATH = 'structures/download'
export const DOWNLOAD_PRODUCT_STRUCTURES_URI = `${PRODUCTS_CONTROLLER}/${DOWNLOAD_PRODUCT_STRUCTURES_SUBPATH}`

export class DownloadProductStructuresDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @ValidateIf(o => !o.allStructuresSelected)
  @IsOptional()
  @IsArray()
  structureIds: string[]

  @IsBoolean()
  @IsOptional()
  allStructuresSelected?: boolean

  @ValidateIf(o => o.allStructuresSelected)
  @IsOptional()
  @IsArray()
  structureFacilities?: string[]

  @ValidateIf(o => o.allStructuresSelected)
  @IsOptional()
  @IsString()
  structureQuery?: string

  @ValidateIf(o => !o.allProductsSelected)
  @IsOptional()
  @IsArray()
  productIds?: string[]

  @IsBoolean()
  @IsOptional()
  allProductsSelected?: boolean

  @ValidateIf(o => o.allProductsSelected)
  @IsOptional()
  @IsArray()
  productCategory?: string[]

  @ValidateIf(o => o.allProductsSelected)
  @IsString()
  @IsOptional()
  productQuery?: string

  @IsBoolean()
  @IsOptional()
  downloadDefaultTemplate?: boolean

  @ValidateIf(o => !o.downloadDefaultTemplate)
  @IsOptional()
  @IsString()
  startDate?: string

  @ValidateIf(o => !o.downloadDefaultTemplate)
  @IsOptional()
  @IsString()
  endDate?: string
}

export type DownloadProductStructuresDTORes = {
  file: { data: Buffer }
  contentType: string
  fileName: string
}
