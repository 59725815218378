import { IsUUID, IsString, IsOptional, IsNumber } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCTS_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_STRUCTURE_INPUTS_SUBPATH = 'structure/:structureId/inputs'
export const GET_PRODUCT_STRUCTURE_INPUTS_URI = `${PRODUCTS_CONTROLLER}/${GET_PRODUCT_STRUCTURE_INPUTS_SUBPATH}`

export class GetProductStructureInputsDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  structureId!: string

  @IsString()
  @IsOptional()
  search?: string

  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number
}

export type StructureInput = {
  inputCode: string
  name: string
  productOrPackaging: string
  sourceCountry: string
  weight: number
  weightUnit: string
  percentageRecycled: number
  supplierName: string
  supplierCountryCode: string
}

export type GetProductStructureInputsDTORes = {
  inputs: StructureInput[]
  total: number
}
