import { IsNumber, IsArray } from 'class-validator'

import { SUPPLIER_SKU_PURCHASES_CONTROLLER } from './supplier-sku-purchases.common-vars'

export class GetSupplierSKUMetadataDTO {
  @IsNumber()
  year!: number

  @IsArray()
  supplierIds!: string[]
}

export type GetSupplierSKUMetadataDTORes = {
  totalAssignedEmissionsQuantity: number
  totalSpendUSD: number
  totalSpendEUR: number
  totalSpendGBP: number
  averageAccuracyScore: number
}

export const GET_SUPPLIER_SKU_METADATA_SUB_PATH = 'metadata'
export const GET_SUPPLIER_SKU_METADATA_PATH = `${SUPPLIER_SKU_PURCHASES_CONTROLLER}/${GET_SUPPLIER_SKU_METADATA_SUB_PATH}`
