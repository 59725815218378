import { IsUUID } from 'class-validator'

import { ValidateDTO } from '../../DTO'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const DELETE_PRODUCT_MODEL_INPUT_SUBPATH = '/inputs/:inputId'
export const DELETE_PRODUCT_MODEL_INPUT_URI = `${PRODUCT_MODELLING_CONTROLLER}${DELETE_PRODUCT_MODEL_INPUT_SUBPATH}`

export class DeleteProductModelInputDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  inputId!: string
}

export type DeleteProductModelInputDTORes = {
  inputId: string
}
