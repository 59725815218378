import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const GET_PACT_REQUESTS_SUBPATH = ''
export const GET_PACT_REQUESTS_URI = `${PACT_REQUEST_CONTROLLER}/${GET_PACT_REQUESTS_SUBPATH}`

export type RequestsPayloadResponse = {
  id: string
  status: string
  createdAt: string
  connectionName: string
}

export class GetPactRequestsDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type GetPactRequestsDTORes = {
  requests: RequestsPayloadResponse[]
}
