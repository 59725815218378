import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { PACT_REQUEST_STATUSES, PACT_REQUEST_TYPES } from '../../../@types/pact/PactRequests'

import { PACT_REQUEST_CONTROLLER } from './controller.common-vars'

export const GET_PACT_REQUEST_SUBPATH = ':id'
export const GET_PACT_REQUEST_URI = `${PACT_REQUEST_CONTROLLER}/${GET_PACT_REQUEST_SUBPATH}`

export type RequestPayloadResponse = {
  id: string
  orgId: string
  connectionId: string
  status: PACT_REQUEST_STATUSES
  createdAt: string
  updatedAt: string
  requestType: PACT_REQUEST_TYPES
  request: never
  response: never
  connection: {
    label: string
  }
}

export class GetPactRequestDTOReq extends ValidateDTO {
  @IsUUID()
  id!: string
}

export type GetPactRequestDTORes = {
  request: RequestPayloadResponse
}
