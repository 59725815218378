/**
 * @deprecated
 */
export enum GET_PRODUCT_FOOTPRINT_REQUESTS_SORT_FIELDS_DEPRECATED {
  AVG_CARBON_VALUE = 'avgCarbonValue',
  PRODUCT_FOOTPRINT_NAME = 'productName',
  SUPPLIER_NAME = 'supplierName',
}

export enum GET_PRODUCT_FOOTPRINT_REQUESTS_SORT_FIELDS {
  SUPPLIER_NAME = 'supplierName',
  SKU_NAME = 'skuName',
}

export enum GET_PRODUCT_FOOTPRINT_REPORT_SORT_FIELDS {
  PRODUCT_NAME = 'productName',
  SUPPLIER_NAME = 'supplierName',
  UNITARY_PRODUCT_AMOUNT = 'unitaryProductAmount',
  FOSSIL_GHG_EMISSIONS = 'fossilGHGEmissions',
  FOSSIL_CARBON_CONTENT = 'fossilCarbonContent',
  BIOGENIC_CARBON_CONTENT = 'biogenicCarbonContent',
  DIRECT_LAND_USE_CHANGE_GHG = 'directLandUseChangeGHG',
  LAND_MANAGEMENT_GHG = 'landManagementGHG',
  OTHER_BIOGENIC_GHG = 'otherBiogenicGHG',
  INDIRECT_LAND_USE_CHANGE_LAND_GHG = 'indirectLandUseChangeLandGHG',
  BIOGENIC_CARBON_WIHTRDAWAL = 'biogenicCarbonWithdrawal',
  AIRCRAFT_GHG = 'aircraftGHG',
  EMISSIONS_EXEMPTED = 'emissionsExempted',
  PACKAGING_GHG = 'packagingGHG',
  COVERAGE = 'coverage',
  TECHNOLOGICAL_DQR = 'technologicalDQR',
  TEMPORAL_DQR = 'temporalDQR',
  GEOGRAPHICALDQR = 'geographicalDQR',
  COMPLETENESS = 'completeness',
  RELIABILITY = 'reliability',
}
