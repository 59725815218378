export enum DATA_SOURCE_TYPES {
  CUSTOM_DATA_PIPELINE = 'custom_data_pipeline',
  TEMPLATE = 'template',
  PDF = 'pdf',
  CALCULATOR = 'calculator',
  PCF_CALCULATOR = 'pcf_calculator',
  FRANCHISE = 'franchise',
  DATA_IMPORTER = 'data_importer',
}

export const DATA_SOURCE_TYPE_NAMES: Record<DATA_SOURCE_TYPES, string> = {
  [DATA_SOURCE_TYPES.CUSTOM_DATA_PIPELINE]: 'Custom pipeline',
  [DATA_SOURCE_TYPES.TEMPLATE]: 'Template',
  [DATA_SOURCE_TYPES.PDF]: 'PDF',
  [DATA_SOURCE_TYPES.CALCULATOR]: 'Calculator',
  [DATA_SOURCE_TYPES.PCF_CALCULATOR]: 'PCF Calculator',
  [DATA_SOURCE_TYPES.FRANCHISE]: 'Franchise',
  [DATA_SOURCE_TYPES.DATA_IMPORTER]: 'Data importer',
}

export enum DATA_SOURCE_TARGET_MODULE {
  CORPORATE = 'corporate',
  PRODUCT = 'product',
}

export enum DATA_SOURCE_ALLOWED_UPDATE_FREQUENCIES {
  YEARLY = 'yearly',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
  FORTNIGHTLY = 'fortnightly',
  WEEKLY = 'weekly',
  VARIABLE = 'variable',
}

export enum DATA_SOURCE_UPLOAD_TYPES {
  DATA = 'data',
  REFERENCE = 'reference',
  CALCULATION_RULES = 'calculation_rules',
  CUSTOM_CALCULATION = 'custom_calculation',
}

export const FILE_TYPES: Record<DATA_SOURCE_UPLOAD_TYPES, string> = {
  [DATA_SOURCE_UPLOAD_TYPES.DATA]: 'Data file',
  [DATA_SOURCE_UPLOAD_TYPES.REFERENCE]: 'Support file',
  [DATA_SOURCE_UPLOAD_TYPES.CALCULATION_RULES]: 'Calculation rules',
  [DATA_SOURCE_UPLOAD_TYPES.CUSTOM_CALCULATION]: 'Custom calculation',
}

export enum MAINTENANCE_SERVICE_ERRORS {
  DATASET_PART_DELETION_OVERWRITTEN_CHECK_FAILED = 'DATASET_PART_DELETION_OVERWRITTEN_CHECK_FAILED',
  DATASET_PART_DELETION_LOCKED_DATE_RANGE_CHECK_FAILED = 'DATASET_PART_DELETION_LOCKED_DATE_RANGE_CHECK_FAILED',
  DATASET_PART_STATUS_VALIDATION_ERROR = 'DATASET_PART_STATUS_VALIDATION_ERROR',
}

export enum MAINTENANCE_SERVICE_WARNINGS {
  DATASET_PART_DELETION_PUBLISHED_CHECK_FAILED = 'DATASET_PART_DELETION_PUBLISHED_CHECK_FAILED',
}

export enum ACTIVE_UPLOAD_TYPES {
  DATA_FILES = 'data-files',
  SUPPORT_FILES = 'support-files',
  CALCULATION_RULES = 'calculation-rules',
  CUSTOM_CALCULATION = 'custom-calculation',
  DATA_CONTRACT = 'data-contract',
}

export enum PRODUCT_DATA_IMPORTER_ENDPOINTS {
  PRODUCT_LIST = 'product-batch',
  PRODUCT_STRUCTURE = 'product-structure-batch',
  PRODUCTION_RECORDS = 'production-record-batch',
}

export enum PRODUCT_MODULE_SUB_TYPES {
  PRODUCT_LIST = 'PRODUCT_LIST',
  PRODUCT_STRUCTURE = 'PRODUCT_STRUCTURE',
  PRODUCTION_RECORDS = 'PRODUCTION_RECORDS',
}

export enum PRODUCT_DATA_IMPORTER_TEMPLATES {
  PRODUCT_LIST = 'pcf-product-list',
  PRODUCT_STRUCTURE = 'pcf-product-structure',
  PRODUCTION_RECORDS = 'pcf-production-records',
  TOTAL_PRODUCT_QUANTITY = 'pcf-total-product-quantity',
}
