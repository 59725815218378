import type { AxiosInstance } from 'axios'

import { replaceUriParams } from '@lib/DTO'

import {
  GET_DATA_REQUESTS_DASHBOARD_PATH_V2,
  type GetDataRequestsDashboardDTORes,
} from '@lib/DTO/data-requests/get-data-requests-dashboard.dto'
import {
  GET_FRANCHISE_REQUESTS_PROGRESS_PATH,
  type GetFranchiseRequestsProgressDTORes,
} from '@lib/DTO/franchise-requests/get-franchise-requests-progress.dto'

import {
  GET_FRANCHISE_REQUEST_PATH,
  type GetFranchiseRequestDTORes,
} from '@lib/DTO/franchise-requests/get-franchise-request.dto'

import { ADD_FOOTPRINT_TO_REQUEST_PATH } from '@lib/DTO/franchise-requests/add-footprint-to-request.dto'

import {
  FINALIZE_CORPORATE_REQUEST_PATH,
  type FinalizeSupplierCorporateRequestDto,
} from '@lib/DTO/supplier-corporate-requests/finalize-supplier-corporate-request.dto'

import {
  GET_SUPPLIER_DATA_REQUESTS_STATS_PATH,
  type GetSupplierDataRequestsStatsDTO,
  type GetSupplierDataRequestsStatsRes,
} from '@lib/DTO/supplier-data-requests/get-supplier-data-requests-stats.dto'

import {
  GET_SUPPLIER_DATA_REQUESTS_PATH,
  type GetDataRequestsDTO,
} from '@lib/DTO/supplier-data-requests/get-data-requests.dto'

import { GET_SUPPLIER_DATA_REQUEST_PATH } from '@lib/DTO/supplier-data-requests/get-data-request.dto'

import {
  FINALIZE_PRODUCT_REQUEST_PATH,
  type FinalizeSupplierProductRequestDto,
} from '@lib/DTO/supplier-product-requests/finalize-supplier-product-request.dto'

import {
  SET_NO_DATA_CORPORATE_REQUEST_PATH,
  type SetNoDataSupplierCorporateRequestDto,
} from '@lib/DTO/supplier-corporate-requests/set-no-data-supplier-corporate-request.dto'

import {
  type GetCCFEmissionsCoverageDto,
  GET_CCF_EMISSIONS_COVERAGE_PATH,
} from '@lib/DTO/supplier-corporate-requests/get-emissions-coverage.dto'

import { type GetCCFStatsDto, GET_CCF_STATS_PATH } from '@lib/DTO/supplier-corporate-requests/get-stats.dto'

import {
  SET_NO_DATA_PRODUCT_REQUEST_PATH,
  type SetNoDataSupplierProductRequestDto,
} from '@lib/DTO/supplier-product-requests/set-no-data-supplier-product-request.dto'

import {
  GET_SUPPLIER_PRODUCT_REQUESTS_PATH,
  type GetProductRequestsDto,
  type GetProductRequestsDtoRes,
} from '@lib/DTO/supplier-product-requests/get-product-requests.dto'

import {
  GET_SUPPLIER_PRODUCT_REQUESTS_V2_PATH,
  type GetProductRequestsV2Dto,
  type GetProductRequestsV2DtoRes,
} from '@lib/DTO/supplier-product-requests/get-product-requests-v2.dto'

import { GET_PCF_EMISSIONS_COVERAGE_PATH } from '@lib/DTO/supplier-product-requests/get-emissions-coverage.dto'

import { GET_PCF_EMISSIONS_COVERAGE_V2_PATH } from '@lib/DTO/supplier-product-requests/get-emissions-coverage-v2.dto'

import type { SupplierRequestsEmissionsCoverage } from '@/imports/@types/suppliers.types'

import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useDataRequestsApi = (http: AxiosInstance) => ({
  getDataRequests(params: GetDataRequestsDTO) {
    const activeOrgId = useOrganizationStore().id || ''
    return http.get(GET_SUPPLIER_DATA_REQUESTS_PATH, {
      params,
      headers: {
        'x-org-id': activeOrgId,
      },
    })
  },

  // Returns an array of data requests
  getDashboardDataRequests() {
    const orgStore = useOrganizationStore()

    return http.get<GetDataRequestsDashboardDTORes>(GET_DATA_REQUESTS_DASHBOARD_PATH_V2, {
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getFranchiseDataRequest(id: string) {
    const orgStore = useOrganizationStore()

    const path = replaceUriParams(GET_FRANCHISE_REQUEST_PATH, {
      id,
    })

    return http.get<GetFranchiseRequestDTORes>(path, {
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getFranchiseDataRequestProgressForYear(year: number) {
    const orgStore = useOrganizationStore()

    return http.get<GetFranchiseRequestsProgressDTORes>(GET_FRANCHISE_REQUESTS_PROGRESS_PATH, {
      params: {
        year,
      },
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  addFootprintToFranchiseRequest(requestId: string, footprintId: string) {
    const orgStore = useOrganizationStore()

    const path = replaceUriParams(ADD_FOOTPRINT_TO_REQUEST_PATH, {
      requestId,
    })

    return http.post(
      path,
      { footprintId },
      {
        headers: {
          'x-org-id': orgStore.id,
        },
      },
    )
  },

  getDataRequest(requestId: string) {
    const activeOrgId = useOrganizationStore().id || ''

    const path = replaceUriParams(GET_SUPPLIER_DATA_REQUEST_PATH, { requestId })
    return http.get(path, {
      headers: {
        'x-org-id': activeOrgId,
      },
    })
  },

  getStats(params: GetSupplierDataRequestsStatsDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetSupplierDataRequestsStatsRes>(GET_SUPPLIER_DATA_REQUESTS_STATS_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  // Deprecated by rollout-automated-sku-aggregation
  getProductDataRequestProgressDeprecated(year: number, cohortId?: string) {
    const orgStore = useOrganizationStore()

    const params = {
      year,
      cohortId: cohortId || undefined,
    }

    return http.get<SupplierRequestsEmissionsCoverage>(GET_PCF_EMISSIONS_COVERAGE_PATH, {
      params,
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getProductDataRequestProgress(year: number, cohortId?: string) {
    const orgStore = useOrganizationStore()

    const params = {
      year,
      cohortId: cohortId || undefined,
    }

    return http.get<SupplierRequestsEmissionsCoverage>(GET_PCF_EMISSIONS_COVERAGE_V2_PATH, {
      params,
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getCorporateDataRequestsProgress(params: GetCCFEmissionsCoverageDto) {
    const orgStore = useOrganizationStore()

    return http.get(GET_CCF_EMISSIONS_COVERAGE_PATH, {
      params,
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getCorporateRequestStats(params: GetCCFStatsDto) {
    const orgStore = useOrganizationStore()

    return http.get(GET_CCF_STATS_PATH, {
      params,
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  finalizeCorporateRequest(payload: FinalizeSupplierCorporateRequestDto, requestId: string) {
    const orgId = useOrganizationStore().id || ''

    const path = replaceUriParams(FINALIZE_CORPORATE_REQUEST_PATH, {
      requestId,
    })

    return http.patch(path, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  setNoDataCorporateRequest(payload: SetNoDataSupplierCorporateRequestDto, requestId: string) {
    const orgId = useOrganizationStore().id || ''

    const path = replaceUriParams(SET_NO_DATA_CORPORATE_REQUEST_PATH, {
      requestId,
    })

    return http.patch<undefined>(path, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  // Deprecated by rollout-automated-sku-aggregation
  getProductRequestsDeprecated(params: GetProductRequestsDto) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetProductRequestsDtoRes>(GET_SUPPLIER_PRODUCT_REQUESTS_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getProductRequests(params: GetProductRequestsV2Dto) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetProductRequestsV2DtoRes>(GET_SUPPLIER_PRODUCT_REQUESTS_V2_PATH, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  finalizeProductRequest(payload: FinalizeSupplierProductRequestDto, requestId: string) {
    const orgId = useOrganizationStore().id || ''

    const path = replaceUriParams(FINALIZE_PRODUCT_REQUEST_PATH, {
      requestId,
    })

    return http.patch<undefined>(path, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  setNoDataProductRequest(payload: SetNoDataSupplierProductRequestDto, requestId: string) {
    const orgId = useOrganizationStore().id || ''

    const path = replaceUriParams(SET_NO_DATA_PRODUCT_REQUEST_PATH, {
      requestId,
    })

    return http.patch<undefined>(path, payload, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
