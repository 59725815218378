import { IsUUID } from 'class-validator'

import { FILE_STATUS } from '../../../../imports/@enums/data-source-file-status.enum'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

export const GET_DATA_SOURCE_FILES_ERRORS_SUBPATH = 'files-errors/data-source/:dataSourceId'
export const GET_DATA_SOURCE_FILES_ERRORS_URI = `${DATASOURCE_CONTROLLER}/${GET_DATA_SOURCE_FILES_ERRORS_SUBPATH}/`

export class GetDataSourceFileErrorsReq extends ValidateDTO {
  @IsUUID()
  dataSourceId!: string
}

export type GetDataSourceFileErrorsRes = {
  datasourceUuid: string
  files: {
    fieldCheckErrors: number
    fileFormatErrors: number
    fileUuid: string
    ingestionErrors: number
    s3Url: string
  }[]
}

export type GetDataSourceErrors = {
  id: string
  fileName: string
  createdAt: string
  status: FILE_STATUS
  deleteRequestedBy?: string
  user: {
    userName: string
    avatar: string | null
    email: string
  }
}
