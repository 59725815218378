import type { AxiosInstance } from 'axios'

import { replaceUriParams } from '@lib/DTO'

import {
  type GetExploreDataEmbedDto,
  type GetExploreDataEmbedDtoRes,
  GET_EXPLORE_DATA_EMBED_PATH,
} from '@lib/DTO/explore-data/get-explore-data-embed.dto'

import { ONBOARD_EXPLORE_DATA_ORG_PATH } from '@lib/DTO/explore-data/onboard-explore-data-organization.dto'

import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useExploreDataApi = (http: AxiosInstance) => ({
  // The embed path can be found in sigma when viewing a workbook
  getEmbedUrl(payload: GetExploreDataEmbedDto) {
    const orgId = useOrganizationStore().id || ''
    const URL = replaceUriParams(GET_EXPLORE_DATA_EMBED_PATH, { embedPath: encodeURIComponent(payload.embedPath) })

    return http.get<GetExploreDataEmbedDtoRes>(URL, {
      params: {
        workbookMenuPosition: payload.workbookMenuPosition,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  onboardSigmaOrganisation(orgId: string) {
    const URL = replaceUriParams(ONBOARD_EXPLORE_DATA_ORG_PATH, { onboardingOrgId: orgId })

    return http.post(URL)
  },
})
