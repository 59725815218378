// eslint-disable-next-line max-classes-per-file
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, Min } from 'class-validator'

import { Expose } from 'class-transformer'

import { ValidateDTO } from '../../DTO'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { PRODUCT_MODELLING_CONTROLLER } from './controller.common-vars'

export const GET_PRODUCT_MODELLING_SUBPATH = '/'
export const GET_PRODUCT_MODELLING_URI = `${PRODUCT_MODELLING_CONTROLLER}${GET_PRODUCT_MODELLING_SUBPATH}`

export enum PRODUCT_MODELLING_SORT_FIELDS {
  PRODUCT_NAME = 'productName',
  INITIATIVE_NAME = 'initiativeName',
  FACILITY_NAME = 'facilityName',
  PCF_START_DATE = 'pcfStartDate',
  EMISSION_CHANGE_FACTOR = 'emissionChangeFactor',
}

export interface IGetModelsArgs {
  orgId: string
  facilityId: string
  searchStr: string
  perPage: number
  pageNo: number
  sortOrder: SORT_ORDER
  sortField: PRODUCT_MODELLING_SORT_FIELDS
}

export class GetProductModellingDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  @IsOptional()
  facilityId?: string

  @IsString()
  @IsNotEmpty()
  @IsOptional()
  searchStr?: string

  @IsNumber()
  @Min(1)
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @Min(1)
  @IsOptional()
  perPage?: number

  @IsEnum(PRODUCT_MODELLING_SORT_FIELDS)
  @IsOptional()
  sortField?: PRODUCT_MODELLING_SORT_FIELDS

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: SORT_ORDER
}

export class ProductModelsEntity {
  @Expose()
  id = ''

  @Expose()
  orgId = ''

  @Expose()
  productName = ''

  @Expose()
  initiativeName = ''

  @Expose()
  facilityId = ''

  @Expose()
  facilityName = ''

  @Expose()
  pcfStartDate = ''

  @Expose()
  emissionChangeFactor = 0
}

export interface GetProductModellingDTORes {
  models: ProductModelsEntity[]

  meta: {
    pageNo: number
    perPage: number
    count: number
    totalCount: number
  }
}
