import { IsNumber, IsOptional, IsUUID } from 'class-validator'

import { SUPPLIER_CORPORATE_REQUESTS_CONTROLLER } from './supplier-corporate-requests.common-vars'

export class GetCCFStatsDto {
  @IsNumber()
  year!: number

  @IsUUID()
  @IsOptional()
  cohortId?: string
}

export const GET_CCF_STATS_SUB_PATH = 'stats-coverage'
export const GET_CCF_STATS_PATH = `${SUPPLIER_CORPORATE_REQUESTS_CONTROLLER}/${GET_CCF_STATS_SUB_PATH}`
